import React, { useEffect, useState } from "react";
import {
  ChipField,
  Datagrid,
  List,
  NumberField,
  TextField,
  BooleanInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  SimpleShowLayout,
  RichTextField,
  useDataProvider,
  Filter,
  FilterProps,
  useAuthProvider,
  UserIdentity,
  CloneButton,
  required,
  ReferenceInput,
  ReferenceField,
  EditButton,
} from "react-admin";
import { Box, Card, Avatar, Button } from "@mui/material";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import { makeStyles } from "@mui/styles";
import { AmountField, DateTimeField } from "../lib/ra-rf/fields";
import {
  CLOUD_PROVIDER,
  CURRENCIES,
  Currency,
  CURRENCY_SYMBOL,
} from "../models";
import { getCurrency } from "../lib/geo";
import { SwitchButton } from "../lib/ra-rf/SwitchButton";

export type Plan = {
  id: string;
  resourceType: "PROXY";
  resourceZone: "FIXED" | "MANUAL";
  name: string;
  description: string;
  currency: Currency;
  price: string;
  validity?: number;
  maxUsage?: number;
  provider?: string;
  geoName: string;
  geoSpace?: string;
};

const RESOURCE_TYPE = [
  { id: "PROXY", name: "PROXY" },
  { id: "VPN", name: "VPN" },
];

const RESOURCE_ZONE = [
  { id: "FIXED", name: "FIXED" },
  { id: "MANUAL", name: "MANUAL" },
];

const useStyles = makeStyles(() => ({
  plansContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 -5px",
  },
  planCard: {
    display: "flex",
    padding: "10px",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
    margin: "3px 5px",
    textAlign: "center",
    maxWidth: "300px",
  },
  // planProvider: {
  //   width: '100%',
  //   transform: "rotate(-45deg) translateX(-33%) translateY(-45px)",
  //   backgroundColor: theme.palette.success.main,
  //   color: theme.palette.grey[50],
  //   fontWeight: 800,
  //   textTransform: "uppercase",
  //   paddingTop: "5px",
  //   paddingBottom: "5px",
  // },
  planHeader: {
    fontSize: "1.2em",
    fontWeight: 800,
    textTransform: "uppercase",
  },
  // planContent: {
  //   color: theme.palette.grey[600],
  //   lineHeight: "1.8em",
  //   padding: "10px",
  // },
  // planPrice: {
  //   fontSize: "1.5em",
  //   fontWeight: 400,
  //   color: theme.palette.primary.main,
  // },
  // plansIcon: {
  //   height: "60px",
  //   width: "60px",
  //   backgroundColor: theme.palette.grey[300],
  //   color: theme.palette.primary.light,
  // },
  // addressCreate: {
  //   position: "absolute",
  //   width: 400,
  //   backgroundColor: theme.palette.background.paper,
  //   border: `2px solid ${theme.palette.primary.dark}`,
  //   boxShadow: theme.shadows[5],
  //   /* padding: theme.spacing(2, 4, 3), */
  //   left: "50%",
  //   top: "50%",
  //   transform: "translate(-50%,-50%)",
  // },
}));

export const PlanCard = ({
  plan,
  buyAction,
  actionText,
  ...props
}: {
  plan: Plan;
  buyAction: (planId: string) => void;
  actionText?: string;
}): React.ReactElement => {
  const classes = useStyles();

  return (
    <Card className={classes.planCard} {...props}>
      <Box
        sx={{
          width: "100%",
          transform: "rotate(-45deg) translateX(-33%) translateY(-45px)",
          bgcolor: "success.main",
          color: "grey.50",
          fontWeight: 800,
          textTransform: "uppercase",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        {plan.geoName}
      </Box>
      <Avatar
        sx={{
          height: "60px",
          width: "60px",
          bgcolor: "grey",
          color: "primary.light",
        }}
      >
        <CardMembershipIcon style={{ fontSize: "2em" }} />
      </Avatar>
      <span style={{ padding: "10px" }}>
        <Box
          component="span"
          sx={{
            fontSize: "1.5em",
            fontWeight: 400,
            color: "primary.main",
          }}
        >
          {CURRENCY_SYMBOL[plan.currency]}
          {plan.price}
        </Box>
        /mo
      </span>
      <div className={classes.planHeader}>{plan.name}</div>
      <Box
        sx={{
          color: "grey[600]",
          lineHeight: "1.8em",
          padding: "10px",
        }}
      >
        <RichTextField
          record={plan}
          label={undefined}
          source="description"
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={() => buyAction(plan.id)}
      >
        {actionText || "BUY NOW"}
      </Button>
    </Card>
  );
};

export interface PlanCardListProps {
  action: (planId: string) => void;
  actionText?: string;
}

export const PlanCardList = ({
  action,
  actionText,
}: PlanCardListProps): React.ReactElement => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const [plans, setPlans] = useState<Plan[]>();
  const [planLoaded, setPlanLoaded] = useState(false);
  const [user, setUser] = useState<UserIdentity>();

  useEffect(() => {
    authProvider.getIdentity &&
      authProvider.getIdentity().then((user) => setUser(user));
  }, [authProvider]);

  useEffect(() => {
    if (user)
      dataProvider
        .getList("plans", {
          pagination: { page: 1, perPage: 10 },
          filter: {
            isActive: true,
            currency: user.country ? getCurrency(user.country) : "USD",
          },
          sort: { field: "price", order: "asc" },
        })
        .then((json) => {
          setPlans(json.data as unknown as Plan[]);
          setPlanLoaded(true);
        });
  }, [dataProvider, user]);

  return planLoaded ? (
    <div className={classes.plansContainer}>
      {plans?.map((plan) => (
        <PlanCard
          key={plan.id}
          plan={plan}
          buyAction={action}
          actionText={actionText}
        />
      ))}
    </div>
  ) : (
    <div></div>
  );
};

export const PlanShow = (): React.ReactElement => (
  <SimpleShowLayout>
    <TextField source="id" />
    <RichTextField source="description" />
    <TextField source="geoSpace" />
    <TextField source="provider" />
    <TextField source="instanceType" />
    <TextField source="location" />
    <DateTimeField source="updatedAt" />
  </SimpleShowLayout>
);

const PlanFilter = (
  props: Omit<FilterProps, "children">
): React.ReactElement => (
  <Filter {...props}>
    <BooleanInput source="isActive" />
    <BooleanInput source="isPublic" />
    <SelectInput source="provider" choices={CLOUD_PROVIDER} alwaysOn />
  </Filter>
);

export const PlanList = (): React.ReactElement => (
  <List sort={{ field: "createdAt", order: "DESC" }} filters={<PlanFilter />}>
    <Datagrid expand={<PlanShow />}>
      <TextField source="name" />
      <ChipField color="default" source="resourceType" />
      <ChipField color="default" source="resourceZone" />
      <ChipField color="secondary" source="geoName" />
      <ReferenceField source="availabilityId" reference="availabilities">
        <TextField source="id" />
      </ReferenceField>
      <SwitchButton source="isActive" />
      <SwitchButton source="isPublic" />
      <AmountField source="price" />
      <NumberField source="validity" />
      <NumberField source="maxUsage" />
      <DateTimeField source="createdAt" />
      <EditButton />
      <CloneButton />
    </Datagrid>
  </List>
);

export const PlanEdit = (): React.ReactElement => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextField source="id" />
      <SelectInput source="resourceType" choices={RESOURCE_TYPE} />
      <TextInput source="name" isRequired />
      <TextInput
        multiline={true}
        source="description"
        isRequired
        validate={[required()]}
      />
      <SelectInput source="resourceZone" choices={RESOURCE_ZONE} />
      <TextInput source="geoSpace" />
      <TextInput source="geoName" isRequired validate={[required()]} />
      <ReferenceInput
        source="availabilityId"
        reference="availabilities"
        allowEmpty
      >
        <SelectInput optionText="id" />
      </ReferenceInput>
      <TextInput source="provider" />
      <TextInput source="location" />
      <TextInput source="instanceType" />
      <BooleanInput source="isActive" />
      <BooleanInput source="isPublic" />
      <SelectInput source="currency" choices={CURRENCIES} />
      <NumberInput source="price" />
      <NumberInput source="validity" />
      <NumberInput source="maxUsage" />
      {/* <DateInput source="createdAt" />
          <DateInput source="updatedAt" /> */}
    </SimpleForm>
  </Edit>
);

export const PlanCreate = (): React.ReactElement => (
  <Create>
    <SimpleForm>
      <TextInput source="id" />
      <SelectInput source="resourceType" choices={RESOURCE_TYPE} />
      <TextInput source="name" isRequired />
      <TextInput
        multiline={true}
        source="description"
        isRequired
        validate={[required()]}
      />
      <SelectInput source="resourceZone" choices={RESOURCE_ZONE} />
      <TextInput source="geoSpace" />
      <TextInput source="geoName" isRequired validate={[required()]} />
      <ReferenceInput
        source="availabilityId"
        reference="availabilities"
        allowEmpty
      >
        <SelectInput optionText="id" />
      </ReferenceInput>
      <TextInput source="provider" />
      <TextInput source="location" />
      <TextInput source="instanceType" />
      <BooleanInput source="isActive" />
      <BooleanInput source="isPublic" />
      <SelectInput source="currency" choices={CURRENCIES} />
      <NumberInput source="price" />
      <NumberInput source="validity" />
      <NumberInput source="maxUsage" />
    </SimpleForm>
  </Create>
);
