import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  SimpleShowLayout,
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  usePermissions,
} from "react-admin";
import { hasPermission } from "../lib/ra-rf/authorization";
import { DateTimeField, JsonField, MinuteField } from "../lib/ra-rf/fields";

const RESOURCE_STATUS = [
  { id: "STOPPED", name: "STOPPED" },
  { id: "RUNNING", name: "RUNNING" },
  { id: "STARTING", name: "STARTING" },
];

const RESOURCE_COLOR = {
  STOPEED: "#fff",
  RUNNING: "#efe",
  STARTING: "#fe0",
};

type STATUS = keyof typeof RESOURCE_COLOR;

const resourceUsageRowStyle = (record: any) => ({
  backgroundColor: RESOURCE_COLOR[record.status as STATUS],
});

export const ResourceUsageShow = () => (
  <SimpleShowLayout>
    <TextField source="id" />
    <TextField source="resourceId" label="Proxy" />
    <JsonField source="details" />
    <DateTimeField source="runningSince" />
    <DateTimeField source="exhaustAt" />
  </SimpleShowLayout>
);

export const ResourceUsageEdit = () => {
  const { permissions } = usePermissions();
  return (
    <Edit>
      <SimpleForm>
        <TextField source="id" />
        <ReferenceField source="resourceId" reference="resources" label="Proxy">
          <TextField source="subdomainId" />
        </ReferenceField>
        {hasPermission("LIST_USER", permissions) && (
          <ReferenceField source="userId" reference="users">
            <TextField source="name" />
          </ReferenceField>
        )}
        <SelectInput source="status" choices={RESOURCE_STATUS} />
        <TextInput source="details" />
        <TextInput source="instanceId" />
        <TextInput source="privateIp" />
        <TextInput source="publicIp" />
      </SimpleForm>
    </Edit>
  );
};
export const ResourceUsageList = () => {
  const { permissions } = usePermissions();
  return (
    <List sort={{ field: "createdAt", order: "DESC" }} perPage={25}>
      <Datagrid rowStyle={resourceUsageRowStyle} expand={<ResourceUsageShow />}>
        {/* <UuidField source="id" /> */}
        <ReferenceField source="resourceId" reference="resources" label="Proxy">
          <TextField source="subdomainId" />
        </ReferenceField>
        {hasPermission("LIST_USER", permissions) && (
          <ReferenceField source="userId" reference="users">
            <TextField source="name" />
          </ReferenceField>
        )}
        <TextField source="publicIp" />
        <TextField source="status" />
        <DateTimeField source="startedAt" label="Start time" />
        <DateTimeField source="stoppedAt" label="Stopped time" />
        <MinuteField source="usage" label="Usage (Hour)" />
      </Datagrid>
    </List>
  );
};
