import { ReactElement } from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  NumberField,
  ReferenceField,
  ChipField,
  ReferenceInput,
  SelectInput,
  SimpleShowLayout,
  Button,
  FilterProps,
  Filter,
  useDataProvider,
  useNotify,
  usePermissions,
  useRecordContext,
} from "react-admin";
import { hasPermission } from "../lib/ra-rf/authorization";
import { AmountField, DateTimeField, UuidField } from "../lib/ra-rf/fields";
import { ORDER_STATUS } from "../models";
import { useMutation } from "react-query";

const OrderFilter = (
  props: Omit<FilterProps, "children">
): React.ReactElement => (
  <Filter {...props}>
    <SelectInput source="status" choices={ORDER_STATUS} alwaysOn />
    <ReferenceInput source="planId" reference="plans" label="Plan" >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const OrderShow = (): ReactElement => (
  <SimpleShowLayout>
    <TextField source="id" />
    <AmountField source="totalPrice" />
    <AmountField source="discount" />
    <AmountField source="tax" />
    <ReferenceField source="paymentId" reference="transactions">
      <TextField source="id" />
    </ReferenceField>
    <TextField source="paidAt" />
    <ReferenceField source="refundId" reference="transactions">
      <TextField source="id" />
    </ReferenceField>
    <TextField source="refundedAt" />
    <DateTimeField source="updatedAt" />
  </SimpleShowLayout>
);

export const OrderList = (): ReactElement => {
  const {permissions} = usePermissions();
  return (
  <List
    sort={{ field: "createdAt", order: "DESC" }}
    perPage={25}
    filters={<OrderFilter />}
  >
    <Datagrid rowClick="edit" expand={<OrderShow />}>
      <UuidField source="id" />
      <ReferenceField
        link={hasPermission("LIST_PLAN", permissions)? "SHOW": undefined}
        source="planId"
        reference="plans"
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="quantity" />
      {hasPermission("LIST_COUPON", permissions) ? (
        <ReferenceField source="couponCode" reference="coupons">
          <TextField source="id" />
        </ReferenceField>
      ) : (
        <TextField source="couponId" />
      )}
      {hasPermission("LIST_USER", permissions) && (
        <ReferenceField source="userId" reference="users">
          <TextField source="name" />
        </ReferenceField>
      )}
      <AmountField source="grossAmount" />
      <ChipField source="status" />
      {/* <ReferenceField source="resourceId" reference="resources"><UuidField source="id" /></ReferenceField> */}
      <DateTimeField source="createdAt" />
      <Resource />
    </Datagrid>
  </List>
);
      }

export const OrderEdit = (): ReactElement => (
  <Edit>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceField source="planId" reference="plans">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="couponCode" reference="coupons">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="userId" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <NumberField source="grossAmount" />
      <TextField source="status" />
      <ReferenceInput source="paymentId" reference="transactions">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <TextInput source="paidAt" />
      <ReferenceInput source="refundId" reference="transactions">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <TextInput source="refundedAt" />
      {/* <ReferenceInput source="resourceId" reference="resources">
        <SelectInput optionText="id" />
      </ReferenceInput> */}
    </SimpleForm>
  </Edit>
);

export const OrderCreate = (): ReactElement => {
  const {permissions} = usePermissions();
  return (
  <Create>
    <SimpleForm>
      {hasPermission("SUPERADMIN", permissions) && (
        <ReferenceInput source="userId" reference="users">
          <SelectInput optionText="email" />
        </ReferenceInput>
      )}
      <ReferenceInput source="planId" reference="plans">
        <SelectInput optionText="description" />
      </ReferenceInput>
      {(hasPermission("SUPERADMIN", permissions) && (
        <ReferenceInput source="couponCode" reference="coupons">
          <SelectInput optionText="id" />
        </ReferenceInput>
      )) || <TextInput source="couponCode" />}
    </SimpleForm>
  </Create>
)};

const Resource = (props: any) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const record = useRecordContext(props);
  const payload = { id: record.id, data: { _action: "RESOURCES" } };
  const { mutate, isLoading } = useMutation(
    ["orders", "update", payload],
    () => dataProvider.update("orders", { previousData: record, ...payload }),
    { 
      onSuccess: () => {
        notify("Resource created", {type: "info"});
        // redirectTo: "/resources",
      },
      onError: () => {
        notify("Error: Resource can not be created", {type: "warning"})
      },
    }
  );
  if (record.status === "PAID")
    return (
      <Button
        label="Create"
        variant="outlined"
        onClick={() => mutate()}
        disabled={isLoading}
      />
    );
  else
    return (
      <ReferenceField source="resourceId" reference="resources">
        <TextField source="subdomainId" />
      </ReferenceField>
    );
};
