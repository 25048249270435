import {
  fetchUtils,
  AuthProvider,
  DataProvider,
  UpdateManyParams,
  UpdateParams,
  GetManyReferenceParams,
  GetManyParams,
  GetOneParams,
  GetListParams,
  DeleteManyParams,
  HttpError,
} from "ra-core";
import { stringify } from "query-string";
import jwt from "jsonwebtoken";

type PaginatedResponse = {
  count: number;
  results: any[];
  next?: {
    _limit: number;
    _offset: number;
    url: string;
  };
  prev?: {
    _limit: number;
    _offset: number;
    url: string;
  };
};

type User = {
  id: string;
  name?: string;
  email: string;
  mobile: string;
  billingAddress?: {
    line_1: string;
    line_2?: string;
    city: string;
    state: string;
    pincode: number;
    createdAt: Date;
    updatedAt: Date;
  };
  roles: string[];
  [key: string]: any;
  country: string;
};

type LoginRequest = {
  username: string;
  password: string;
};

type Response = {
  status: number;
  headers: Headers;
  body: string;
  json: any;
};

const getCookie = (cname: string) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const deleteCookie = (cname: string) => {
  const d = new Date();
  d.setTime(d.getTime() - 600000);
  const expires = "expires=" + d.toUTCString();
  document.cookie =
    cname +
    "= {};" +
    expires +
    ";domain=." +
    process.env.REACT_APP_BASE_URL +
    ";path=/";
};

class RestFramework implements AuthProvider, DataProvider {
  private apiUrl: string;
  private user: Promise<User>;
  private httpClient: (
    url: any,
    options?: fetchUtils.Options | undefined
  ) => Promise<Response> = (url, options = {}) => {
    options.credentials = "include";
    /* if(this.token){
        options.user = {
          authenticated: true,
          token: `Bearer ${this.token.bearer_token}`
        }
      } */
    return fetchUtils.fetchJson(url, options);
  };

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
    this.user = this.getUser();
  }

  private getUser = async () =>
    this.httpClient(`${this.apiUrl}/auth`).then((resp) => {
      // Try setting tawk user
      try {
        const setTawkUser = () => {
          (window as any).Tawk_API.setAttributes(
            {
              name: resp.json.name,
              email: resp.json.email,
              hash: resp.json.tawk_hash,
            },
            function (error: any) {
              console.warn("Tawk", error);
            }
          );
        };
        if (typeof (window as any).Tawk_API.setAttributes === "undefined") {
          (window as any).Tawk_API.onLoad = setTawkUser;
        } else {
          setTawkUser();
        }
      } catch (e) {
        console.warn("Exception in setting user in Chat", e);
      }
      return resp.json;
    });

  private guest = async (): Promise<User> =>
    new Promise((resolve, reject) => reject());

  private getCredentialFromCookie = () => {
    const cookie = getCookie("token");
    if (cookie) {
      try {
        const json = JSON.parse(cookie);
        const decoded = jwt.decode(json.bearer_token, {
          json: true,
          complete: true,
        });
        json.roles = decoded?.payload.roles;
        localStorage.setItem("user", JSON.stringify(json));
        deleteCookie("user");
      } catch (err) {
        console.error("Invalid user token");
        deleteCookie("user");
      }
    }
    const user = localStorage.getItem("user");
    console.log("user", user);
    if (user) {
      this.user = JSON.parse(user);
    }
  };

  // send username and password to the auth server and get back credentials
  login = async ({ username, password }: LoginRequest) => {
    const url = `${this.apiUrl}/auth`;
    return this.httpClient(url, {
      method: "POST",
      body: JSON.stringify({
        email: username,
        password,
      }),
    }).then(async (resp) => {
      localStorage.setItem("token", JSON.stringify(resp.json));
      return (this.user = this.getUser());
    }).catch((err: HttpError) => {
      throw new Error(
          err.body.errors.map((error: any) => error.message).join("\n")
      )
    });
  };

  // remove local credentials and notify the auth server that the user logged out
  logout = async () => {
    const url = `${this.apiUrl}/auth/logout`;
    return this.httpClient(url, {
      method: "POST",
    })
      .then((/* resp */) => {
        this.user = this.guest();
        localStorage.removeItem("token");
        return Promise.resolve();
      })
      .catch((/* err */) => {
        return Promise.resolve();
      });
  };

  // when the dataProvider returns an error, check if this is an authentication error
  checkError = ({ status }: { status: number }) => {
    if (status === 401) {
      this.user = this.guest();
      localStorage.removeItem("token");
      return Promise.reject();
    } else if (status === 403) {
      return Promise.resolve();
    }
    return Promise.resolve();
  };

  // when the user navigates, make sure that their credentials are still valid
  checkAuth = () => this.user.then(() => Promise.resolve());

  // get the user permissions (optional)
  getPermissions = () =>
    this.user.then((user) => user.roles).catch((/* err */) => []);

  // get the user's profile
  getIdentity = () =>
    this.user.then((user) => ({ ...user, fullName: user.name }));

  getUserAddress = () => this.user.then((user) => user.billingAddress);

  get = async (resource: string, query: Record<string, string>) =>
    this.httpClient(`${this.apiUrl}/${resource}?${stringify(query)}`).then(
      ({ /* headers, */ json }) => json
    );

  post = async (resource: string, data: any) =>
    this.httpClient(`${this.apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then(({ /* headers, */ json }) => json);

  getList = async (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      _sort: field,
      _order: order,
      _limit: perPage,
      _offset: (page - 1) * perPage,
    };
    const url = `${this.apiUrl}/${resource}?${stringify(query)}`;

    return this.httpClient(url).then(({ /* headers, */ json }) => {
      if (Array.isArray(json)) {
        return {
          data: json,
          total: json.length,
        };
      } else {
        const paginatedResponse = json as PaginatedResponse;
        return {
          data: paginatedResponse.results,
          total: paginatedResponse.count,
        };
      }
    });
  };

  getOne = (resource: string, params: GetOneParams) =>
    this.httpClient(`${this.apiUrl}/${resource}/${params.id}`).then(
      ({ json }) => ({
        data: json,
      })
    );

  getMany = async (resource: string, params: GetManyParams) => {
    const query = {
      id: params.ids,
    };
    const url = `${this.apiUrl}/${resource}?${stringify(query)}`;
    return this.httpClient(url).then(({ json }) => ({ data: json }));
  };

  getManyReference = async (
    resource: string,
    params: GetManyReferenceParams
  ) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      _sort: field,
      _order: order,
      _limit: perPage,
      _offset: (page - 1) * perPage,
    };
    const url = `${this.apiUrl}/${resource}?${stringify(query)}`;

    return this.httpClient(url).then(({ /* headers, */ json }) => {
      if (Array.isArray(json)) {
        return {
          data: json,
          total: json.length,
        };
      } else {
        const paginatedResponse = json as PaginatedResponse;
        return {
          data: paginatedResponse.results,
          total: paginatedResponse.count,
        };
      }
    });
  };

  update = async (resource: string, params: UpdateParams<any>) => {
    const { _action, ...data } = params.data;
    console.log(_action);
    if (_action) {
      // If _action is presend post to _action endpoint
      return this.httpClient(
        `${this.apiUrl}/${resource}/${params.id}/${_action}`,
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      )
        .then(({ json }) => ({ data: json }))
        .catch((err: HttpError) => {
          if (err.status === 400) {
            throw new Error(
              err.body.errors.map((error: any) => error.message).join("\n")
            );
          } else {
            throw err;
          }
        });
    }
    return this.httpClient(`${this.apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(data),
    })
      .then(({ json }) => ({ data: json }))
      .catch((err: HttpError) => {
        if (err.status === 400) {
          throw new Error(
            err.body.errors.map((error: any) => error.message).join("\n")
          );
        } else {
          throw err;
        }
      });
  };

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany = async (resource: string, params: UpdateManyParams<any>) => {
    const allRequests = params.ids.map((id) =>
      this.httpClient(`${this.apiUrl}/${resource}/${id}`, {
        method: "PUT",
        body: JSON.stringify(params.data),
      })
    );
    return Promise.all(allRequests).then((responses) => ({
      data: responses.map(({ json }) => json.id),
    }));
  };

  create = (resource: string, params: any) =>
    this.httpClient(`${this.apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    })
      .then(({ json }) => ({
        // data: { ...params.data, id: json.id },
        data: json,
      }))
      .catch((err: HttpError) => {
        if (err.status === 400) {
          throw new Error(
            err.body.errors.map((error: any) => error.message).join("\n")
          );
        } else {
          throw err;
        }
      });

  delete = (resource: string, params: any) =>
    this.httpClient(`${this.apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany = (resource: string, params: DeleteManyParams) =>
    Promise.all(
      params.ids.map((id) =>
        this.httpClient(`${this.apiUrl}/${resource}/${id}`, {
          method: "DELETE",
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
}

export default RestFramework;
