// import OrderChart from "./components/chart/orders";
import { useState } from "react";
import { DashboardComponent, useNotify } from "react-admin";
import { NewOrder } from "./components/newOrder";
import { DefaultAlert, InfoAlert } from "./components/ui/alert";
import { PlanCardList } from "./components/plans";
import ShowStats from "./components/stats";

const Dashboard: DashboardComponent = ({ permissions }) => {
  const notify = useNotify();
  const [planId, setPlanId] = useState("");

  const orderSuccess = (json: unknown) => {
    console.log(json);
    notify("Proxy created");
    window.location.reload();
  }

  const orderError = (message: string) => {
    notify(message);
    setPlanId("");
  }
  return (
    <>
      <div style={{display: 'flex', flexWrap: "wrap", marginRight: "-5px"}}>
        <InfoAlert style={{flexGrow: 1, marginRight: "5px"}}>
          To renew expired proxies, select all the proxies which you want to renew then click on Renew button on top
        </InfoAlert>
        <DefaultAlert style={{flexGrow: 1, marginRight: "5px"}}>
          Use code BULK10 to get 10% discount on order above ₹800
        </DefaultAlert>
      </div>
      <ShowStats />
      <div style={{ marginBottom: "5px" }}>
          {permissions && 
          <div style={{ /*maxWidth: "1200px"*/ }}>
            <PlanCardList action={setPlanId} />
            <NewOrder planId={planId} orderSuccess={orderSuccess} orderError={orderError} />
          </div>
          }
      </div>
    </>
  );
};

export default Dashboard;
