import React from "react";
import {
  Datagrid,
  List,
  TextField,
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  FilterProps,
  CloneButton,
  EditButton,
} from "react-admin";
import { SwitchButton } from "../lib/ra-rf/SwitchButton";
import { DateTimeField } from "../lib/ra-rf/fields";

export type Region = {
  id: string;
  namespace: string;
  isActive: boolean;
  isPublic: boolean;
};


const RegionFilter = (
  props: Omit<FilterProps, "children">
): React.ReactElement => (
  <Filter {...props}>
    <BooleanInput source="isActive" />
    <BooleanInput source="isPublic" />
  </Filter>
);

export const RegionList = (): React.ReactElement => (
  <List
    sort={{ field: "createdAt", order: "DESC" }}
    filters={<RegionFilter />}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="namespace" />
      <SwitchButton source="isActive" />
      <SwitchButton source="isPublic" />
      <DateTimeField source="createdAt" />
      <EditButton />
      <CloneButton />
    </Datagrid>
  </List>
);

export const RegionEdit = (): React.ReactElement => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
      <TextInput source="namespace" />
      <BooleanInput source="isActive" />
      <BooleanInput source="isPublic" />
    </SimpleForm>
  </Edit>
);

export const RegionCreate = (): React.ReactElement => (
  <Create>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
      <TextInput source="namespace" />
      <BooleanInput source="isActive" />
      <BooleanInput source="isPublic" />
    </SimpleForm>
  </Create>
);
