export const hasPermission = (
  permission: string,
  permissions?: string[]
): boolean => {
  if (typeof permissions === "undefined" || !permissions || !permission)
    return false;
  if (permissions.indexOf("SUPERADMIN") !== -1) return true;
  if (permissions.indexOf(permission) !== -1) return true;
  return false;
};
