import {
  ImageField,
  useGetIdentity,
  SimpleShowLayout,
  TextField,
  Title,
} from "react-admin";
import { Card } from "@mui/material";

export const ViewProfile = () => {
  console.log("Profile Show");
  const { isLoading, identity } = useGetIdentity();

  if (isLoading) {
    return null;
  }

  return (
    <Card>
      <Title>My Profile</Title>
      <SimpleShowLayout record={identity ? identity : { id: "GUEST" }}>
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="mobile" />
        <ImageField source="avatar" />
      </SimpleShowLayout>
    </Card>
  );
};
