import { Card, CardContent } from "@mui/material";

export const Alert = ({ children, ...props }: any) => {
  return (
    <Card {...props}>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export const InfoAlert = (props: any) => (
  <Alert
    {...props}
    sx={{
      marginTop: "5px",
      marginBottom: "5px",
      bgcolor: 'info.light',
      color: 'grey.50',
    }}
  />
);

export const ErorrAlert = (props: any) => (
  <Alert {...props} 
  sx={{
    marginTop: "5px",
    marginBottom: "5px",
    bgcolor: 'error.light',
    color: 'grey.50',
  }} />
);

export const DefaultAlert = (props: any) => (
  <Alert {...props} 
  sx={{
    marginTop: "5px",
    marginBottom: "5px",
    color: 'primary.light',
    bgcolor: 'grey.50',
  }} />
);

export const PrimaryAlert = (props: any) => (
  <Alert {...props} 
  sx={{
    marginTop: "5px",
    marginBottom: "5px",
    bgcolor: 'primary.light',
    color: 'grey.50',
  }} />
);

export const SuccessAlert = (props: any) => (
  <Alert {...props} 
  sx={{
    marginTop: "5px",
    marginBottom: "5px",
    bgcolor: 'success.light',
    color: 'grey.50',
  }} />
);
