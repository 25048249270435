import { ReactElement } from "react";
import { get } from "lodash";
import { useRecordContext } from "react-admin";
import {
  Done,
  Close,
  PlayCircleOutline,
  StopCircleOutlined,
  Cached,
  PublishedWithChanges,
  Timer,
  DoDisturb,
  HourglassBottom
} from '@mui/icons-material';

const iconMap: Record<string,ReactElement<any,any>> = {
  "SUCCESS": <Done color="success" />,
  "FAILURE": <Close color="error" />,
  "START": <PlayCircleOutline color="primary" />,
  "STOP": <StopCircleOutlined color="primary" />,
  "RUNNING": <PublishedWithChanges color="success" />,
  "STARTING": <Cached color="info" />,
  "EXPIRED": <Timer color="warning" />,
  "EXHAUSTED": <HourglassBottom color="warning" />,
  "REVOKED": <DoDisturb color="error" />,
  "STOPPED": <StopCircleOutlined color="primary" />,
}

const IconField = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = get(record, source);
  const element = iconMap[value];
  if (element) return element;
  return <span>{value}</span>;
};

export default IconField;