import { useState, ReactElement } from "react";
import {
  BulkActionProps,
  Button,
  useNotify,
  useRefresh,
  useUnselectAll,
} from "react-admin";
import { Box, Modal } from "@mui/material";
import { PlanCardList } from "./plans";
// import { makeStyles } from '@mui/styles';
import Autorenew from "@mui/icons-material/Autorenew";
import { NewOrder } from "./newOrder";

// const useStyles = makeStyles((theme) => ({
//   modalDiv: {
//     position: "absolute",
//     width: '80%',
//     maxWidth: 1200,
//     backgroundColor: theme.palette.background.paper,
//     border: `2px solid ${theme.palette.primary.dark}`,
//     boxShadow: theme.shadows[5],
//     /* padding: theme.spacing(2, 4, 3), */
//     left: "50%",
//     top: "50%",
//     transform: "translate(-50%,-50%)",
//   },
// }));

const RenewProxiesButton = ({ selectedIds }: BulkActionProps): ReactElement => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("resources");
  const [showPlan, setShowPlan] = useState(false);
  const [planId, setPlanId] = useState("");
  // const classes = useStyles();

  const orderSuccess = (json: unknown) => {
    console.log(json);
    refresh();
    notify("Proxy Renewed");
    unselectAll();
  };

  const orderError = (message: string) => {
    notify(message);
    setPlanId("");
  };

  const handleClick = () => setShowPlan(true);
  const handleDialogClose = () => setShowPlan(false);

  const handlePlanChoose = (planId: string) => {
    setPlanId(planId);
    setShowPlan(false);
  };

  return (
    <>
      <Button label="Renew" onClick={handleClick}>
        <Autorenew />
      </Button>
      <Modal open={showPlan} onClose={handleDialogClose}>
        <Box
          sx={{
            position: "absolute",
            width: 400,
            bgcolor: "background.paper",
            border: 2,
            borderColor: "primary.dark",
            boxShadow: 5,
            /* padding: theme.spacing(2, 4, 3), */
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <PlanCardList action={handlePlanChoose} actionText="Select Plan" />
        </Box>
      </Modal>
      <NewOrder
        planId={planId}
        resourceIds={selectedIds}
        orderSuccess={orderSuccess}
        orderError={orderError}
      />
    </>
  );
};

export default RenewProxiesButton;
