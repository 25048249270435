import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  EmailField,
  BooleanInput,
  PasswordInput,
  Filter,
  FilterProps,
  EditButton,
} from "react-admin";
import { SwitchButton } from "../lib/ra-rf/SwitchButton";
import { DateTimeField } from "../lib/ra-rf/fields";

const UserFilter = (props: Omit<FilterProps, "children">): React.ReactElement => (
  <Filter {...props} >
    <TextInput source="email" alwaysOn />
    <TextInput source="mobile" alwaysOn />
  </Filter>
)

export const UserList = () => (
  <List sort={{ field: "createdAt", order: "DESC" }} perPage={25} filters={<UserFilter />} >
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="mobile" />
      <SwitchButton source="emailVerified" />
      <SwitchButton source="mobileVerified" />
      <SwitchButton source="isActive" />
      <DateTimeField source="createdAt" />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="mobile" />
      <BooleanInput source="isActive" />
    </SimpleForm>
  </Edit>
);

export const UserCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="mobile" />
      <PasswordInput source="password" />
    </SimpleForm>
  </Create>
);
