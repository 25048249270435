import {
  Admin,
  CustomRoutes,
  defaultTheme,
  Layout,
  Login,
  Resource,
} from "react-admin";
import { concat, merge } from "lodash";
import {
  Group,
  GroupWork,
  Category,
  QueryBuilder,
  CloudDone,
  DonutSmall,
  LocalOffer,
  CloudUpload,
  MonetizationOn,
  Payment,
  ShoppingBasket,
  LocalAtm,
  History,
  ContactMail,
  Domain,
  FilterDrama,
  MapRounded,
} from "@mui/icons-material";
import Dashboard from "./dashboard";
import restFramework from "./provider/rf";
import MyAppBar from "./components/MyAppBar";
import { RegionCreate, RegionEdit, RegionList } from "./components/regions";
import { PlanCreate, PlanEdit, PlanList } from "./components/plans";
import { RoleCreate, RoleEdit, RoleList } from "./components/roles";
import {
  PermisssionCreate,
  PermisssionEdit,
  PermisssionList,
} from "./components/permissions";
import { UserCreate, UserEdit, UserList } from "./components/users";
import { CouponCreate, CouponEdit, CouponList } from "./components/coupons";
import { OrderCreate, OrderEdit, OrderList } from "./components/orders";
import {
  TransactionCreate,
  TransactionEdit,
  TransactionList,
} from "./components/transactions";
import { ExportList, InvoiceEdit, InvoiceList } from "./components/invoices";
import {
  ResourceCreate,
  ResourceEdit,
  ResourceList,
} from "./components/resources";
import {
  ScheduleCreate,
  ScheduleEdit,
  ScheduleList,
} from "./components/schedules";
import red from "@mui/material/colors/red";
import {
  ResourceUsageEdit,
  ResourceUsageList,
} from "./components/resourceUsages";
import {
  AddressCreate,
  AddressEdit,
  AddressList,
} from "./components/addresses";
import { ActivityList } from "./components/activities";
import {
  SubdomainCreate,
  SubdomainEdit,
  SubdomainList,
} from "./components/subdomains";
import {
  ResourceGroupCreate,
  ResourceGroupEdit,
  ResourceGroupList,
} from "./components/resourceGroups";
import {
  AvailabilityCreate,
  AvailabilityEdit,
  AvailabilityList,
} from "./components/availabilities";
import TreeMenu from "./components/ui/TreeMenu";
import { Route } from "react-router-dom";
// import { ProfileEdit } from "./components/EditProfile";
import { ViewProfile } from "./components/ViewProfile";
// import { createMuiTheme } from '@mui/material/styles';

/* const muiTheme = createMuiTheme(
  {
    palette: {
      secondary: {
        main: "#eeeeee",
        light: "#ffffff",
        dark: "#bcbcbc",
        contrastText: "#ff4081"
      },
      primary: {
        main: "#f50057",
        light: "#ff5983",
        dark: "#bb002f",
        contrastText: "#fafafa"
      },
      error: red,
      contrastThreshold: 3,
      tonalOffset: 0.2,
    }
  }
) */

const myTheme = merge({}, defaultTheme, {
  palette: {
    secondary: {
      main: "#eeeeee",
      light: "#ffffff",
      dark: "#bcbcbc",
      contrastText: "#ff4081",
    },
    primary: {
      main: "#f50057",
      light: "#ff5983",
      dark: "#bb002f",
      contrastText: "#fafafa",
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const MyLoginPage = () => (
  <Login
    // theme={myTheme}
    // A random image that changes everyday
    backgroundImage="https://www.cloudproxy.in/assets/img/hero-area.svg"
  />
);

const MyLayout = (props: any) => (
  <Layout {...props} appBar={MyAppBar} menu={TreeMenu} />
);

const App = () => (
  <Admin
    disableTelemetry={true}
    loginPage={MyLoginPage}
    title="Cloudproxy"
    theme={myTheme}
    dataProvider={restFramework}
    dashboard={Dashboard}
    authProvider={restFramework}
    layout={MyLayout}
    requireAuth
  >
    {(permissions) =>
      concat(
        permissions && permissions.indexOf("SUPERADMIN") !== -1
          ? [
              <Resource
                name="user-managements"
                options={{ label: "User & Role", isMenuParent: true }}
              />,
              <Resource
                name="roles"
                list={RoleList}
                edit={RoleEdit}
                create={RoleCreate}
                icon={Category}
                options={{ menuParent: "user-managements" }}
              />,
              <Resource
                name="permissions"
                list={PermisssionList}
                edit={PermisssionEdit}
                create={PermisssionCreate}
                icon={DonutSmall}
                options={{ menuParent: "user-managements" }}
              />,
              <Resource
                name="users"
                list={UserList}
                edit={UserEdit}
                create={UserCreate}
                icon={Group}
                options={{ menuParent: "user-managements" }}
              />,
              <Resource
                name="addresses"
                list={AddressList}
                edit={AddressEdit}
                create={AddressCreate}
                icon={ContactMail}
                options={{ menuParent: "user-managements" }}
              />,
              <Resource
                name="region-managements"
                options={{ label: "Avail Zone", isMenuParent: true }}
              />,
              <Resource
                name="regions"
                list={RegionList}
                edit={RegionEdit}
                create={RegionCreate}
                icon={MapRounded}
                options={{ menuParent: "region-managements" }}
              />,
              <Resource
                name="availabilities"
                list={AvailabilityList}
                edit={AvailabilityEdit}
                create={AvailabilityCreate}
                icon={FilterDrama}
                options={{ menuParent: "region-managements" }}
              />,
              <Resource
                name="marketplace"
                options={{ label: "Marketplace", isMenuParent: true }}
              />,
              <Resource
                name="plans"
                list={PlanList}
                edit={PlanEdit}
                create={PlanCreate}
                icon={LocalOffer}
                options={{ menuParent: "marketplace" }}
              />,
              <Resource
                name="coupons"
                list={CouponList}
                edit={CouponEdit}
                create={CouponCreate}
                icon={LocalAtm}
                options={{ menuParent: "marketplace" }}
              />,
              <Resource
                name="orders"
                list={OrderList}
                edit={OrderEdit}
                create={OrderCreate}
                icon={ShoppingBasket}
                options={{ menuParent: "marketplace" }}
              />,
              <Resource
                name="transactions"
                list={TransactionList}
                edit={TransactionEdit}
                create={TransactionCreate}
                icon={Payment}
                options={{ menuParent: "marketplace" }}
              />,
              <Resource
                name="invoices"
                list={InvoiceList}
                edit={InvoiceEdit}
                icon={MonetizationOn}
                options={{ menuParent: "marketplace" }}
              />,
              <Resource
                name="sudo/exports"
                list={ExportList}
                icon={MonetizationOn}
                options={{ menuParent: "marketplace", label: "Exports" }}
               />,
              <Resource
                name="resource-managements"
                options={{ label: "Resource", isMenuParent: true }}
              />,
              <Resource
                name="subdomains"
                list={SubdomainList}
                edit={SubdomainEdit}
                create={SubdomainCreate}
                icon={Domain}
                options={{ menuParent: "resource-managements" }}
              />,
              <Resource
                name="resources"
                options={{
                  label: "Proxies",
                  menuParent: "resource-managements",
                }}
                list={ResourceList}
                edit={ResourceEdit}
                create={ResourceCreate}
                icon={CloudUpload}
              />,
              <Resource
                name="resource_usages"
                options={{
                  label: "Usages",
                  menuParent: "resource-managements",
                }}
                list={ResourceUsageList}
                edit={ResourceUsageEdit}
                icon={CloudDone}
              />,
              <Resource
                name="resource_groups"
                options={{
                  label: "Groups",
                  menuParent: "resource-managements",
                }}
                list={ResourceGroupList}
                edit={ResourceGroupEdit}
                create={ResourceGroupCreate}
                icon={GroupWork}
              />,
              <Resource
                name="schedules"
                list={ScheduleList}
                edit={ScheduleEdit}
                create={ScheduleCreate}
                icon={QueryBuilder}
                options={{ menuParent: "resource-managements" }}
              />,
            ]
          : [
              <Resource name="coupons" icon={LocalAtm} />,
              <Resource name="users" icon={Group} />,
              <Resource name="plans" icon={LocalOffer} />,
              <Resource
                name="resources"
                options={{ label: "Proxies" }}
                list={ResourceList}
                icon={CloudUpload}
              />,
              <Resource
                name="resource_groups"
                options={{ label: "Groups" }}
                list={ResourceGroupList}
                edit={ResourceGroupEdit}
                create={ResourceGroupCreate}
                icon={GroupWork}
              />,
              <Resource
                name="schedules"
                list={ScheduleList}
                edit={ScheduleEdit}
                create={ScheduleCreate}
                icon={QueryBuilder}
              />,
              <Resource
                name="resource_usages"
                options={{ label: "Usages" }}
                list={ResourceUsageList}
                icon={CloudDone}
              />,
              <Resource name="orders" list={OrderList} icon={ShoppingBasket} />,
              <Resource
                name="transactions"
                list={TransactionList}
                icon={Payment}
              />,
              <Resource
                name="invoices"
                list={InvoiceList}
                icon={MonetizationOn}
              />,
              <Resource name="addresses" />,
              <Resource name="regions" />,
            ],
        permissions
          ? [
              <Resource name="activities" list={ActivityList} icon={History} />,
              <CustomRoutes>
                <Route path="/my-profile" element={<ViewProfile />} />
              </CustomRoutes>,
            ]
          : [
            ]
      )
    }
  </Admin>
);

export default App;
