import React, { ReactElement, useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  Create,
  NumberInput,
  SimpleShowLayout,
  ChipField,
  DateTimeInput,
  TextInput,
  PasswordInput,
  FunctionField,
  useNotify,
  useDataProvider,
  BulkActionProps,
  BulkDeleteButton,
  Filter,
  FilterProps,
  SelectArrayInput,
  usePermissions,
  useRecordContext,
  WrapperField,
  EditButton,
  useUpdate,
} from "react-admin";
import { IconButtonWithTooltip } from "ra-ui-materialui";
import {
  PlaylistAdd,
  PlayArrow,
  Stop,
  Sync,
  FileCopyOutlined as FileCopyIcon,
} from "@mui/icons-material";
import { ListItem } from "@mui/material";

import { DateTimeField, JsonField, MinuteField } from "../lib/ra-rf/fields";
import { hasPermission } from "../lib/ra-rf/authorization";
import { DefaultAlert } from "./ui/alert";
import RenewProxiesButton from "./renewOrder";
import { CLOUD_PROVIDER, RESOURCE_STATUS } from "../models";
import { ActionField } from "../lib/ra-rf/ActionField";
import DropDown from "./ui/DropDown";
import IconField from "../lib/ra-rf/IconField";
import { ShowHideField } from "../lib/ra-rf/ShowHideField";

const RESOURCE_COLOR = {
  EXPIRED: "#fee",
  EXHAUSTED: "#fee",
  REVOKED: "#fee",
  STOPEED: "#fff",
  RUNNING: "#efe",
  STARTING: "#cef",
};

type STATUS = keyof typeof RESOURCE_COLOR;

type ResourceGroup = {
  id: string;
  name: string;
};

type Region = {
  id: string;
  name: string;
  namespace: string;
};

const resourceRowStyle = (record: any) => ({
  backgroundColor: RESOURCE_COLOR[record.status as STATUS],
});

const ResourceFilter = (
  props: Omit<FilterProps, "children">
): React.ReactElement => (
  <Filter {...props}>
    <SelectArrayInput
      source="status"
      choices={RESOURCE_STATUS}
      alwaysOn
      style={{ minWidth: "100px" }}
    />
    <ReferenceInput
      source="regionId"
      reference="regions"
      label="Region"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="resourceGroupId"
      reference="resource_groups"
      label="Group"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const ResourceAdminFilter = (
  props: Omit<FilterProps, "children">
): React.ReactElement => (
  <Filter {...props}>
    <SelectArrayInput
      source="status"
      choices={RESOURCE_STATUS}
      alwaysOn
      style={{ minWidth: "100px" }}
    />
    <SelectArrayInput
      source="provider"
      choices={CLOUD_PROVIDER}
      alwaysOn
      style={{ minWidth: "100px" }}
    />
    <ReferenceInput
      source="regionId"
      reference="regions"
      label="Region"
      alwaysOn
    >
      <SelectInput optionText="id" />
    </ReferenceInput>
    <ReferenceInput
      source="resourceGroupId"
      reference="resource_groups"
      label="Group"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const ResourceShow = (): ReactElement => (
  <SimpleShowLayout>
    <TextField source="id" />
    <ReferenceField source="orderId" reference="orders">
      <TextField source="id" />
    </ReferenceField>
    <ReferenceField source="lastResourceUsageId" reference="resource_usages">
      <TextField source="id" />
    </ReferenceField>
    <JsonField source="config" />
    <DateTimeField source="createdAt" />
    <DateTimeField source="updatedAt" />
  </SimpleShowLayout>
);

const BulkActionButtons = (props: BulkActionProps) => {
  return <RenewProxiesButton {...props} />;
};

const AdminBulkActionButtons = (props: BulkActionProps) => {
  return (
    <>
      <RenewProxiesButton {...props} />
      <BulkDeleteButton {...props} />
    </>
  );
};

export const ResourceList = (): ReactElement => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [resource, setResource] = useState({ id: 1, geoSpace: "xxxx." });

  const [resourceGroups, setResourceGroups] = useState<ResourceGroup[]>();
  const [resourceGroupLoaded, setResourceGroupLoaded] = useState(false);
  const [resourceGroupEl, setResourceGroupEl] =
    useState<HTMLButtonElement | null>(null);

  const [regions, setRegions] = useState<Region[]>();
  const [regionLoaded, setRegionLoaded] = useState(false);
  const [regionEl, setRegionEl] = useState<HTMLButtonElement | null>(null);
  const [update] = useUpdate();

  useEffect(() => {
    if (!resourceGroupLoaded)
      dataProvider
        .getList("resource_groups", {
          pagination: { page: 1, perPage: 100 },
          filter: {},
          sort: { field: "name", order: "asc" },
        })
        .then((json) => {
          if (!json) {
            console.error("Something went wrong in fetching resource groups");
            return;
          }
          setResourceGroups(json.data as unknown as ResourceGroup[]);
          setResourceGroupLoaded(true);
        });
  }, [dataProvider, resourceGroupLoaded]);

  useEffect(() => {
    if (!regionLoaded)
      dataProvider
        .getList("regions", {
          pagination: { page: 1, perPage: 100 },
          filter: { isActive: true, isPublic: true },
          sort: { field: "name", order: "asc" },
        })
        .then((json) => {
          if (!json) {
            console.error("Something went wrong in fetching resource groups");
            return;
          }
          setRegions(json.data as unknown as Region[]);
          setRegionLoaded(true);
        });
  }, [dataProvider, regionLoaded]);

  const chooseResourceGroup = (
    event: React.MouseEvent<HTMLButtonElement>,
    resource: any
  ) => {
    setResourceGroupEl(event.currentTarget);
    setResource(resource);
    // setOpenGroupDialog(true);
  };

  const assignResourceGroup = (id: string) => {
    // setOpenGroupDialog(false);
    setResourceGroupEl(null);
    update(
      "resources",
      {
        id: resource.id,
        data: {
          _action: "GROUP",
          resourceGroupId: id,
        },
        previousData: resource,
      },
      {
        mutationMode: "optimistic",
        onError: (error: any) => {
          notify(error.message, { type: "warning" });
        },
      }
    );
  };

  const chooseRegion = (
    event: React.MouseEvent<HTMLButtonElement>,
    resource: any
  ) => {
    setRegionEl(event.currentTarget);
    setResource(resource);
  };

  const assignRegion = (id: string) => {
    setRegionEl(null);
    update(
      "resources",
      {
        id: resource.id,
        data: {
          _action: "REGION",
          regionId: id,
        },
        previousData: resource,
      },
      {
        mutationMode: "optimistic",
        onError: (error: any) => {
          notify(error.message, { type: "warning" });
        },
      }
    );
  };

  const { permissions } = usePermissions();

  return (
    <React.Fragment>
      <DefaultAlert>
        All proxies are available on ports: 3128, 8000 &amp; 8080. IP is
        available while proxy is running. You can use domain in place of IP to
        avoid configuring every time.
      </DefaultAlert>
      <List
        sort={{ field: "createdAt", order: "DESC" }}
        perPage={25}
        filters={
          hasPermission("SUPERADMIN", permissions) ? (
            <ResourceAdminFilter />
          ) : (
            <ResourceFilter />
          )
        }
        bulkActionButtons={
          hasPermission("SUPERADMIN", permissions) ? (
            <AdminBulkActionButtons />
          ) : (
            <BulkActionButtons />
          )
        }
      >
        <Datagrid
          // rowClick={
          //   hasPermission("SUPERADMIN", permissions) ? "edit" : undefined
          // }
          rowStyle={resourceRowStyle}
          expand={<ResourceShow />}
        >
          <FunctionField
            label="Domain"
            render={(record: any) =>
              record.subdomainId
                ? `${record.subdomainId}${process.env.REACT_APP_RESOURCE_DOMAIN}`
                : ""
            }
          />
          <ActionButton />
          {hasPermission("SUPERADMIN", permissions) && (
            <ChipField source="resourceType" />
          )}
          {hasPermission("SUPERADMIN", permissions) && (
            <ChipField color="secondary" source="provider" />
          )}
          <TextField source="geoName" label="Location" />
          <ActionField
            source="regionId"
            label="Region"
            onClick={chooseRegion}
          />
          {/* <FunctionField label="Port" render={(record: any) => "3128"} /> */}
          {/* <UuidField source="id" /> */}
          <TextField label="IP" source="publicIp" />
          <TextField source="username" label="User" />
          <ShowHideField source="password" />
          {hasPermission("LIST_USER", permissions) && (
            <ReferenceField source="userId" reference="users">
              <TextField source="name" />
            </ReferenceField>
          )}
          <DateTimeField source="validTill" />
          <WrapperField label="Usage (Hr)" sortBy="currentUsage">
            <MinuteField source="currentUsage" />
            <span> / </span>
            <MinuteField source="maxUsage" />
          </WrapperField>
          <IconField source="status" />
          <ReferenceField
            label="Group"
            source="resourceGroupId"
            reference="resource_groups"
          >
            <TextField source="name" />
          </ReferenceField>
          <GroupButton onClick={chooseResourceGroup} />
          {hasPermission("SUPERADMIN", permissions) && <EditButton />}
        </Datagrid>
      </List>
      <DropDown
        anchorEl={resourceGroupEl}
        onClose={() => setResourceGroupEl(null)}
      >
        {resourceGroups?.map((resourceGroup) => (
          <ListItem
            onClick={() => assignResourceGroup(resourceGroup.id)}
            button
            key={resourceGroup.id}
          >
            {resourceGroup.name}
          </ListItem>
        ))}
      </DropDown>
      <DropDown anchorEl={regionEl} onClose={() => setRegionEl(null)}>
        {regions
          ?.filter((region) => region.namespace.startsWith(resource.geoSpace))
          .map((region) => (
            <ListItem
              onClick={() => assignRegion(region.id)}
              button
              key={region.id}
            >
              {region.name}
            </ListItem>
          ))}
      </DropDown>
    </React.Fragment>
  );
};

export const ResourceEdit = () => (
  <Edit>
    <SimpleForm /*mutationMode="pessimistic"*/>
      <TextField source="id" />
      <TextField source="resourceType" />
      <ReferenceField source="userId" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceInput source="subdomainId" reference="subdomains">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <TextInput source="username" />
      <PasswordInput source="password" />
      <DateTimeInput source="validTill" />
      <NumberInput source="maxUsage" />
      <SelectInput source="status" choices={RESOURCE_STATUS} />
      {/* <ReferenceInput source="resourceGroupId" reference="resource_groups" >
        <SelectInput optionText="name" emptyValue={null} />
      </ReferenceInput> */}
      <TextInput source="geoName" />
      <TextInput source="geoSpace" />
      {/* <ReferenceInput source="regionId" reference="regions">
        <SelectInput optionText="name" />
      </ReferenceInput> */}
      <ReferenceInput source="availabilityId" reference="availabilities">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <TextInput source="provider" />
      <TextInput source="instanceType" />
      <TextInput source="location" />
    </SimpleForm>
  </Edit>
);

export const ResourceCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="orderId" reference="orders">
        <SelectInput optionText="id" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const STATUS_MAP = {
  STOPPED: "start",
  RUNNING: "stop",
  // "STARTING": "refresh"
};

const ActionButton = (props: any) => {
  const record = useRecordContext(props);
  const notify = useNotify();
  let _action = "";
  if (record.status in STATUS_MAP) {
    _action =
      STATUS_MAP[record.status as "STOPPED" | "RUNNING" /* | "STARTING" */];
  }
  const [update, { isLoading }] = useUpdate(
    "resources",
    {
      id: record.id,
      data: { _action: _action.toUpperCase() },
      previousData: record,
    },
    {
      onSuccess: () => {
        notify("Resource updating", { type: "info" });
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );
  if (record.status in STATUS_MAP) {
    return (
      <>
        <CopyButton {...props} />
        <IconButtonWithTooltip
          style={{ padding: 0 }}
          label={_action}
          onClick={() => update()}
          disabled={isLoading}
          color="primary"
        >
          {_action === "start" ? <PlayArrow /> : <Stop />}
        </IconButtonWithTooltip>
      </>
    );
  }
  return (
    <>
      <CopyButton {...props} />
      {record.status === "STARTING" && (
        <IconButtonWithTooltip
          style={{ padding: 1 }}
          label="starting"
          color="default"
        >
          <Sync />
        </IconButtonWithTooltip>
      )}
    </>
  );
};

const GroupButton = ({ onClick, ...props }: any) => {
  const record = useRecordContext(props);
  return (
    <IconButtonWithTooltip
      style={{ padding: 1 }}
      onClick={(event) => onClick(event, record)}
      label="Add to Group"
    >
      <PlaylistAdd />
    </IconButtonWithTooltip>
  );
};

const CopyButton = (props: any) => {
  const notify = useNotify();
  const record = useRecordContext(props);
  const value = `${record.subdomainId}${process.env.REACT_APP_RESOURCE_DOMAIN}:8000:${record.username}:${record.password}`;
  const copy = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        notify(`Copied : ${value}`);
      })
      .catch(() => {
        window.prompt("Click Ctrl+C & press enter", value);
      });
  };
  return (
    <IconButtonWithTooltip
      style={{ padding: 1 }}
      onClick={copy}
      label="Copy domain and login info"
      color="primary"
    >
      <FileCopyIcon />
    </IconButtonWithTooltip>
  );
};
