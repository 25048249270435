import React from "react";
import { Checkbox } from "@mui/material";
import {
  useNotify,
  useRecordContext,
  useResourceContext,
  useUpdate,
} from "react-admin";
import { get } from "lodash";

export const SwitchButton = ({ source, ...props }: any) => {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const notify = useNotify();
  const value = get(record, source);
  
  const [update, { isLoading }] = useUpdate(resource, {
    id: record.id,
    data: { [source]: !value },
    previousData: record,
  }, {
    mutationMode: 'optimistic',
    onError: (error: any) => notify(error.message, { type: "warning" }),
  });

  return (
    <Checkbox
      disabled={isLoading}
      checked={value}
      onClick={() => update()}
      color="primary"
      style={{ padding: 0 }}
    />
  );
};
