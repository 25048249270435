import {
  List,
  Datagrid,
  TextField,
  DateField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  NumberField,
  BooleanInput,
  NumberInput,
  DateTimeInput,
  SimpleShowLayout,
  ChipField,
  SelectInput,
  EditButton,
} from "react-admin";
import { SwitchButton } from "../lib/ra-rf/SwitchButton";
import { AmountField, DateTimeField } from "../lib/ra-rf/fields";
import { COUPON_TYPES, CURRENCIES } from "../models";

export const CouponShow = () => (
  <SimpleShowLayout>
    <NumberField source="currentUsage" />
    <NumberField source="maxUsage" />
    <NumberField source="totalUsage" />
    <DateTimeField source="createdAt" />
    <DateTimeField source="updatedAt" />
  </SimpleShowLayout>
);

export const CouponList = () => (
  <List sort={{ field: "createdAt", order: "ASC" }}>
    <Datagrid expand={<CouponShow />}>
      <TextField source="id" />
      <ChipField color="secondary" source="type" />
      <NumberField source="value" />
      <SwitchButton source="isActive" />
      <AmountField source="minAmount" />
      <AmountField source="maxDiscount" />
      <DateField source="validFrom" />
      <DateField source="validTill" />
      <EditButton />
    </Datagrid>
  </List>
);

export const CouponEdit = () => (
  <Edit>
    <SimpleForm>
      <SelectInput source="type" choices={COUPON_TYPES} />
      <SelectInput source="currency" choices={CURRENCIES} />
      <NumberInput source="value" />
      <NumberInput source="minAmount" />
      <NumberInput source="maxDiscount" />
      <NumberInput source="maxUsage" />
      <DateTimeInput source="validFrom" />
      <DateTimeInput source="validTill" />
      <BooleanInput source="isActive" />
    </SimpleForm>
  </Edit>
);

export const CouponCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="id" />
      <SelectInput source="type" choices={COUPON_TYPES} />
      <SelectInput source="currency" choices={CURRENCIES} />
      <NumberInput source="value" />
      <NumberInput source="minAmount" />
      <NumberInput source="maxDiscount" />
      <NumberInput source="maxUsage" />
      <DateTimeInput source="validFrom" />
      <DateTimeInput source="validTill" />
      <BooleanInput source="isActive" />
    </SimpleForm>
  </Create>
);
