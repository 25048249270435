import React, { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import {
    List,
    MenuItem,
    ListItemIcon,
    Typography,
    Collapse,
    Tooltip
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

const useStyles = makeStyles(
    theme => ({
        icon: { minWidth: theme.spacing(5) },
        sidebarIsOpen: {
        '& a': {
            paddingLeft: theme.spacing(3),
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            },
        },
    sidebarIsClosed: {
        '& a': {
            paddingLeft: theme.spacing(2),
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            },
        },
        menuItem: {},
        menuItemName: {
            color: theme.palette.secondary
        },
        openMenuItem: {}
    }),
    { name: 'RaTreeCustomMenuItem' }
);

const CustomMenuItem = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    children,
    dense
}) => {
    const classes = useStyles();
    const translate = useTranslate();

    const header = (
        <MenuItem
            key={name}
            dense={dense}
            button='button'
            onClick={handleToggle}
            className={classnames(
                classes.menuItem,
                { [classes.openMenuItem]: isOpen }
            )}
        >
            <ListItemIcon className={classes.icon}>
                {isOpen ? <ExpandMore /> : icon}
            </ListItemIcon>
            <Typography
                variant="inherit"
                className={classnames(
                    classes.menuItemName,
                    'menuItemName'
                )}
            >
                {translate(name)}
            </Typography>
        </MenuItem>
    );

    return (
        <Fragment>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                    <Tooltip title={translate(name)} placement="right">
                        {header}
                    </Tooltip>
                )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    className={
                        sidebarIsOpen
                            ? classes.sidebarIsOpen
                            : classes.sidebarIsClosed
                    }
                    style={{ display: "flex", flexDirection: "column", alignItems: 'left' }}
                >
                    {children}
                </List>
            </Collapse>
        </Fragment>
    );
};

export default CustomMenuItem;