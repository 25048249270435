import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ReferenceInput,
  SelectInput,
  InputProps,
  AutocompleteInput,
  usePermissions,
  CreateProps,
} from "react-admin";
import { hasPermission } from "../lib/ra-rf/authorization";
import { DateTimeField, UuidField } from "../lib/ra-rf/fields";
import { useWatch } from "react-hook-form";
import { countriesByName, countryList } from "../lib/geo";

const StateInput = (props: InputProps) => {
  // const { values } = useFormState();
  const country = useWatch({ name: "country" });
  return (
    <SelectInput
      choices={
        country
          ? countriesByName[country].stateProvinces?.map((state) => ({
              id: state.name,
              name: state.name,
            }))
          : []
      }
      {...props}
    />
  );
};

export const AddressEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="line_1" />
        <TextInput source="line_2" />
        <TextInput source="city" />
        <StateInput source="state" />
        <SelectInput
          source="country"
          choices={countryList}
          defaultValue="India"
        />
        <TextInput source="pincode" label="Zip/Pin Code" />
      </SimpleForm>
    </Edit>
  );
};

export const BillingAddressCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <h2 style={{ width: "100%" }}>Enter your Billing Address</h2>
      <TextInput source="line_1" />
      <TextInput source="line_2" />
      <TextInput source="city" />
      <StateInput source="state" />
      <SelectInput
        source="country"
        choices={countryList}
        defaultValue="India"
      />
      <TextInput source="pincode" label="Zip/Pin Code" />
    </SimpleForm>
  </Create>
);

export const AddressCreate = () => {
  const { permissions } = usePermissions();
  return (
    <Create>
      <SimpleForm>
        {hasPermission("SUPERADMIN", permissions) && (
          <ReferenceInput
            source="userId"
            reference="users"
            enableGetChoices={({ email }) => email?.length >= 2}
          >
            <AutocompleteInput
              optionText="email"
              filterToQuery={(q: string) =>
                q.length >= 2 ? { email: q } : true
              }
            />
          </ReferenceInput>
        )}
        <TextInput source="line_1" />
        <TextInput source="line_2" />
        <TextInput source="city" />
        <StateInput source="state" />
        <SelectInput
          source="country"
          choices={countryList}
          defaultValue="India"
        />
        <TextInput source="pincode" label="Zip/Pin Code" />
      </SimpleForm>
    </Create>
  );
};

export const AddressList = () => {
  const { permissions } = usePermissions();
  return (
    <List sort={{ field: "createdAt", order: "DESC" }} perPage={25}>
      <Datagrid rowClick="edit">
        <UuidField source="id" />
        {hasPermission("SUPERADMIN", permissions) && (
          <ReferenceField source="userId" reference="users">
            <TextField source="name" />
          </ReferenceField>
        )}
        <TextField source="line_1" />
        <TextField source="line_2" />
        <TextField source="city" />
        <TextField source="state" />
        <TextField source="country" />
        <TextField source="pincode" label="Zip/Pin Code" />
        <DateTimeField source="updatedAt" />
      </Datagrid>
    </List>
  );
};
