import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  BooleanField,
  ChipField,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  Create,
  SimpleShowLayout,
  useNotify,
  usePermissions,
  useRecordContext,
} from "react-admin";
import {
  AmountField,
  DateTimeField,
  JsonField,
  UuidField,
} from "../lib/ra-rf/fields";
import { Button, Filter, FilterProps } from "ra-ui-materialui";
import { loadScript } from "../lib/utils";
import { hasPermission } from "../lib/ra-rf/authorization";
import { TRANSACTION_STATUS } from "../models";

const PAYMENT_GATEWAYS = [
  { id: "PAYU", name: "PAYU" },
  { id: "RAZORPAY", name: "RAZORPAY" },
  { id: "UPI", name: "UPI" },
  { id: "PAYTM", name: "PAYTM" },
  { id: "CASH", name: "CASH" },
];

const TransactionFilter = (props: Omit<FilterProps, "children">): React.ReactElement => (
  <Filter {...props} >
    <SelectInput source="status" choices={TRANSACTION_STATUS} alwaysOn />
  </Filter>
)

export const TransactionShow = () => (
  <SimpleShowLayout>
    <TextField source="id" />
    <TextField source="orderId" />
    <JsonField source="details" />
    <DateTimeField source="createdAt" />
    <DateTimeField source="updatedAt" />
  </SimpleShowLayout>
);

export const TransactionList = () => { 
  const {permissions} = usePermissions();
  return (
  <List sort={{ field: "createdAt", order: "DESC" }} perPage={25} filters={<TransactionFilter />}>
    <Datagrid rowClick="edit" expand={<TransactionShow />}>
      <UuidField source="id" />
      <ReferenceField source="orderId" reference="orders">
        <UuidField source="id" />
      </ReferenceField>
      {hasPermission("LIST_USER", permissions) && (
        <ReferenceField source="userId" reference="users">
          <TextField source="name" />
        </ReferenceField>
      )}
      <ChipField color="secondary" source="status" />
      <AmountField source="amount" />
      <ChipField color="secondary" source="pg" />
      <TextField source="pgPayId" />
      {hasPermission("SUPERADMIN", permissions) && (
        <BooleanField source="settled" />
      )}
      <MakePaymentButton />
    </Datagrid>
  </List>
);
      }

export const TransactionEdit = () => (
  <Edit>
    <SimpleForm /*mutationMode="pessimistic"*/>
      <TextField source="id" />
      <ReferenceField source="orderId" reference="orders">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="userId" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="status" />
      <TextField source="amount" />
      <TextField source="pg" />
      <TextInput source="pgPayId" />
    </SimpleForm>
  </Edit>
);

export const TransactionCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="orderId" reference="orders">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <SelectInput source="pg" choices={PAYMENT_GATEWAYS} />
    </SimpleForm>
  </Create>
);

const MakePaymentButton = (props: any) => {
  const notify = useNotify();
  const record = useRecordContext(props);
  const makePayment: React.MouseEventHandler = async (evt) => {
    evt.preventDefault();
    if (typeof (window as any).Razorpay === "undefined") {
      const razorpayLoaded = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!razorpayLoaded) {
        notify("Unable to load razorpay");
        return;
      }
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: (parseFloat(record.amount) * 100).toString(),
        order_id: record.pgId,
        handler: async (response: any) => {
          const data = {
            orderId: record.pgId,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          console.log(data);
        },
      };
      const paymentObject = new (window as any).Razorpay(options);
      return paymentObject.open();
    }
  };

  if (record.status === "INITIATED" && record.pg === "RAZORPAY" && record.pgId)
    return <Button variant="contained" label="Pay" onClick={makePayment} />;
  else return null;
};
