import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Edit,
  SimpleForm,
  Create,
  TextInput,
  ReferenceInput,
  SelectInput,
  usePermissions,
} from "react-admin";
import { hasPermission } from "../lib/ra-rf/authorization";
import { DateTimeField, UuidField } from "../lib/ra-rf/fields";

export const ResourceGroupList = () => {
  const { permissions } = usePermissions();
  return (
    <List sort={{ field: "createdAt", order: "DESC" }} perPage={25}>
      <Datagrid rowClick="edit">
        <UuidField source="id" />
        {hasPermission("LIST_USER", permissions) && (
          <ReferenceField source="userId" reference="users">
            <TextField source="name" />
          </ReferenceField>
        )}
        <TextField source="name" />
        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export const ResourceGroupEdit = () => {
  const { permissions } = usePermissions();
  return (
    <Edit>
      <SimpleForm /*mutationMode="pessimistic"*/>
        <TextField source="id" />
        {hasPermission("LIST_USER", permissions) && (
          <ReferenceField source="userId" reference="users">
            <TextField source="name" />
          </ReferenceField>
        )}
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  );
};

export const ResourceGroupCreate = () => {
  const { permissions } = usePermissions();
  return (
    <Create>
      <SimpleForm>
        {hasPermission("LIST_USER", permissions) && (
          <ReferenceInput source="userId" reference="users">
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};
