import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  usePermissions,
} from "react-admin";
import { hasPermission } from "../lib/ra-rf/authorization";
import { DateTimeField, UuidField } from "../lib/ra-rf/fields";

export const ActivityList = () => {
  const {permissions} = usePermissions();
  return (
    <List sort={{ field: "createdAt", order: "DESC" }} perPage={25}>
      <Datagrid>
        <UuidField source="id" />
        {hasPermission("SUPERADMIN", permissions) && (
          <ReferenceField source="userId" reference="users">
            <TextField source="name" />
          </ReferenceField>
        )}
        <TextField source="ipAddress" />
        <TextField source="type" />
        <DateTimeField source="createdAt" />
      </Datagrid>
    </List>
  );
};
