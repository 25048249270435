import * as React from "react";
import { memo, FC, ElementType } from "react";
import get from "lodash/get";
import { IconButton } from "@mui/material";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useRecordContext } from "ra-core";

import { sanitizeFieldRestProps } from "./sanitizeFieldRestProps";
import { PublicFieldProps, InjectedFieldProps, fieldPropTypes } from "./types";

export const ShowHideField: FC<ShowHideFieldProps> = memo((props) => {
  const { className, source, emptyText, ...rest } = props;
  const record = useRecordContext(props);
  const value = get(record, source as string);
  const [hidden, setHidden] = React.useState(true);
  const showText = hidden
    ? "* * * * * *"
    : value != null && typeof value !== "string"
    ? JSON.stringify(value)
    : value || emptyText;

  return (
    <Typography
      className={className}
      {...sanitizeFieldRestProps(rest)}
      variant="span"
    >
      {showText}
      <IconButton onClick={() => setHidden(!hidden)} size="small">
        {hidden ? (
          <VisibilityOff fontSize="small" />
        ) : (
          <Visibility fontSize="small" />
        )}
      </IconButton>
    </Typography>
  );
});

// what? TypeScript loses the displayName if we don't set it explicitly
ShowHideField.displayName = "ShowHideField";

ShowHideField.propTypes = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
};

export interface ShowHideFieldProps
  extends PublicFieldProps,
    InjectedFieldProps,
    Omit<TypographyProps, "textAlign"> {
  // TypographyProps do not expose the component props, see https://github.com/mui-org/material-ui/issues/19512
  component?: ElementType<any>;
}
