import React, { forwardRef } from "react";
import { UserMenu, MenuItemLink, useLogout } from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings";
import { useProfile } from "./EditProfile";
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';

const MyLogoutButton = forwardRef<HTMLLIElement>((props, ref) => {
    const logout = useLogout();
    const handleClick = () => logout();
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
        >
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText>
            Logout
          </ListItemText>
        </MenuItem>
    );
});


const MyUserMenu = (props: any) => {
  const { profileVersion } = useProfile();

  return (
    <UserMenu key={profileVersion} {...props}>
      <MenuItemLink
        to="/my-profile"
        primaryText="My Profile"
        leftIcon={<SettingsIcon />}
      />
      <MyLogoutButton />
    </UserMenu>
  );
};

export default MyUserMenu;
