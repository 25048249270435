import PropTypes from "prop-types";
import {
  useRecordContext,
} from "react-admin";
import {ArrowDropDown} from "@mui/icons-material";
import { Button } from "ra-ui-materialui";
import { get } from "lodash";

export const ActionField = (props: any) => {
  const { onClick,icon, ...remaining } = props;
  const {source} = remaining;
  const record = useRecordContext(props);
  return (
    <Button 
      endIcon={icon?? <ArrowDropDown />}
      onClick={(event) => onClick(event, record)}
      label={get(record, source)?? '- - - - -'}
      size="medium"
      variant="text"
      color="inherit"
    />
  );
};

ActionField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.element
};
