import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  ChipField,
  NumberField,
  SimpleShowLayout,
  Edit,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  useRecordContext,
} from "react-admin";
import { Button } from "ra-ui-materialui";
import { DateTimeField, AmountField } from "../lib/ra-rf/fields";
import PrintIcon from "@mui/icons-material/Print";
import { CURRENCIES } from "../models";

export const InvoiceShow = () => (
  <SimpleShowLayout>
    <TextField source="id" />
    <ReferenceField source="transactionId" reference="transactions">
      <TextField source="id" />
    </ReferenceField>
    <ReferenceField source="orderId" reference="orders">
      <TextField source="id" />
    </ReferenceField>
    <AmountField source="itemsPrice" />
    <AmountField source="discount" />
    <AmountField source="amount" />
    <AmountField source="tax" />
    <DateTimeField source="createdAt" />
    <DateTimeField source="updatedAt" />
  </SimpleShowLayout>
);

export const InvoiceList = () => (
  <List sort={{ field: "invoiceNo", order: "DESC" }} perPage={25}>
    <Datagrid expand={<InvoiceShow />}>
      <TextField source="financialYear" />
      <NumberField source="invoiceNo" />
      <DateField source="invoiceDate" />
      {/* <UuidField source="id" /> */}
      {/* <ReferenceField source="orderId" reference="orders">
        <UuidField source="id" />
      </ReferenceField> */}
      <ReferenceField source="userId" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ChipField color="secondary" source="status" />
      <AmountField source="grossAmount" />
      <AmountField source="tax" />
      <ReferenceField source="addressId" reference="addresses" label="State">
        <TextField source="state" />
      </ReferenceField>
      <ReferenceField source="addressId" reference="addresses" label="Country">
        <TextField source="country" />
      </ReferenceField>
      <PrintInvoice />
    </Datagrid>
  </List>
);

export const InvoiceEdit = (): React.ReactElement => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" />
      <ReferenceInput source="orderId" reference="orders">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <ReferenceInput source="userId" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="addressId"
        reference="addresses"
        filterToQuery={(q: string) => (q.length >= 2 ? { line_1: q } : true)}
      >
        <AutocompleteInput optionText="line_1" />
      </ReferenceInput>
      <ReferenceInput source="transactionId" reference="transactions">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <NumberInput source="invoiceNo" />
      <DateInput source="financialYear" />
      <DateInput source="invoiceDate" />
      <TextInput source="status" />
      <SelectInput source="currency" choices={CURRENCIES} />
      <NumberInput source="itemsPrice" />
      <NumberInput source="discount" />
      <NumberInput source="amount" />
      <NumberInput source="tax" />
      <NumberInput source="grossAmount" />
      <DateInput source="createdAt" />
      <DateInput source="updatedAt" />
    </SimpleForm>
  </Edit>
);

const PrintInvoice = (props: any) => {
  const record = useRecordContext(props);
  return (
    <a
      target="__blank"
      href={`${process.env.REACT_APP_PUBLIC_URL}/invoice.html?invoice_id=${record.id}`}
    >
      <Button>
        <PrintIcon />
      </Button>
    </a>
  );
};


export const ExportList = () => (
  <List>
      <Datagrid>
          <TextField source="id" label="State" />
          <TextField source="count" label="Total Invoices" />
          <TextField source="invoiceAmount" />
          <TextField source="tax" />
          <TextField source="total" />
      </Datagrid>
  </List>
);