import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  Create,
  EditButton,
  usePermissions,
  AutocompleteInput,
  TextInput,
} from "react-admin";
import { hasPermission } from "../lib/ra-rf/authorization";
import { DateTimeField, MinuteField, UuidField } from "../lib/ra-rf/fields";
import IconField from "../lib/ra-rf/IconField";
import { SwitchButton } from "../lib/ra-rf/SwitchButton";
import TimeInput from "../lib/TimeInput";
import { DefaultAlert } from "./ui/alert";

const RESOURCE_ACTION_STATUS = [
  { id: "STOP", name: "STOP" },
  { id: "START", name: "START" },
];

export const ScheduleList = () => {
  const { permissions } = usePermissions();
  return (
    <List sort={{ field: "createdAt", order: "DESC" }} perPage={25}>
      <Datagrid>
        <UuidField source="id" />
        <IconField source="action" />
        <ReferenceField source="resourceId" reference="resources" label="Proxy">
          <TextField source="subdomainId" />
        </ReferenceField>
        <ReferenceField
          source="resourceGroupId"
          reference="resource_groups"
          label="Group"
        >
          <TextField source="name" />
        </ReferenceField>
        {hasPermission("LIST_USER", permissions) && (
          <ReferenceField source="userId" reference="users">
            <TextField source="name" />
          </ReferenceField>
        )}
        <MinuteField source="runAt" />
        <DateTimeField source="lastRunAt" label="Last run" />
        <IconField source="lastRunStatus" label="Last status" />
        {/* <TextField source="lastRunStatus" label="Last status" /> */}
        <SwitchButton source="isActive" />
        <DateTimeField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const ScheduleEdit = () => {
  const { permissions } = usePermissions();
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm sx={{ maxWidth: 300 }}>
        {hasPermission("LIST_USER", permissions) && (
          <ReferenceField source="userId" reference="users">
            <TextField source="name" />
          </ReferenceField>
        )}
        <ReferenceInput source="resourceId" reference="resources">
          <TextInput disabled source="subdomainId" fullWidth label="Proxy" />
        </ReferenceInput>
        <ReferenceInput source="resourceGroupId" reference="resource_groups" >
          <TextInput disabled source="name" fullWidth label="Group" />
        </ReferenceInput>
        <TimeInput source="runAt" step={5} />
        <SelectInput
          choices={RESOURCE_ACTION_STATUS}
          source="action"
          fullWidth
        />
        {/* <BooleanInput source="isActive" /> */}
      </SimpleForm>
    </Edit>
  );
};

export const ScheduleCreate = () => (
  <div>
    <DefaultAlert>
      Automatically start/stop proxy at specified time. To set schedule for
      single proxy select proxy or choose a Group to start all proxy belonging
      to a group at once.
      <br />
    </DefaultAlert>
    <Create>
      <SimpleForm sx={{ maxWidth: 300 }}>
        <ReferenceInput
          source="resourceId"
          reference="resources"
          label="Proxy"
          // enableGetChoices={({ subdomainId }) => subdomainId?.length >= 2}
        >
          <AutocompleteInput
            helperText="Select either proxy or group. Do not select both"
            optionText="subdomainId"
            filterToQuery={(q: string) =>
              q?.length >= 2 ? { subdomainId: q } : true
            }
            fullWidth
          />
        </ReferenceInput>
        <ReferenceInput
          source="resourceGroupId"
          reference="resource_groups"
          label="Group"
        >
          <AutocompleteInput
            optionText="name"
            filterToQuery={(q: string) => (q?.length >= 2 ? { name: q } : true)}
            fullWidth
          />
        </ReferenceInput>
        <TimeInput source="runAt" step={5} />
        <SelectInput
          choices={RESOURCE_ACTION_STATUS}
          source="action"
          fullWidth
        />
        {/* <BooleanInput source="isActive" /> */}
      </SimpleForm>
    </Create>
  </div>
);
