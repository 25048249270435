import React from "react";
import PropTypes from "prop-types";
import { FunctionField, useRecordContext } from "react-admin";
import { Currency, CURRENCY_SYMBOL } from "../../models";
import { get } from "lodash";

export const UuidField = (props: any) => {
  return (
    <FunctionField
      {...props}
      render={(record: any) => `${get(record, props.source).replace(/-.*-/, "-")}`}
    />
  );
};

export const JsonField = (props: any) => {
  return (
    <FunctionField
      {...props}
      render={(record: any) =>
        record[props.source]
          ? Object.keys(record[props.source])
              .map((key) => `${key}: ${record[props.source][key]}`)
              .join(",")
          : ""
      }
    />
  );
};

export const DateTimeField = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  return (
    <span>
      {get(record, source) ? new Date(get(record, source)).toLocaleString() : ""}
    </span>
  );
};

DateTimeField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};


export const AmountField = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const currency = (record['currency']?? 'INR') as Currency;
  return (
    <span>
      {get(record, source) ? `${CURRENCY_SYMBOL[currency]}${parseFloat(get(record, source)).toFixed(2)}` : ""}
    </span>
  );
};

AmountField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export const MinuteField = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);
  const value = parseInt(get(record, source));
  const hour = value ? Math.floor(value / 60) : 0;
  const minute = value ? value % 60 : 0;
  return (
    <span>{`${hour < 10 ? `0${hour}` : hour}:${
      minute < 10 ? `0${minute}` : minute
    }`}</span>
  );
};

MinuteField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export const SuperAdminOnly = ({ permissions, children, others }: any) =>
  permissions.indexOf("SUPERADMIN") !== -1 ? children : others ? others : null;

SuperAdminOnly.prototype = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.element.isRequired,
  others: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};
