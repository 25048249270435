import * as React from "react";
import { FunctionComponent } from "react";
import PropTypes from "prop-types";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Box } from "@mui/material";
import { useInput, FieldTitle, InputProps } from "ra-core";
import { InputHelperText } from "react-admin";
import { sanitizeInputRestProps } from "ra-ui-materialui";

const getHourFromTime = (value: string) => {
  // console.log("Get hour", value);
  const number = parseInt(value);
  return isNaN(number) ? "0" : "" + Math.floor(number / 60);
};

const getMinuteFromTime = (value: string) => {
  // console.log("Get minute", value);
  const number = parseInt(value);
  return isNaN(number) ? "0" : "" + (number % 60);
};

/**
 * An Input component for time
 *
 * @example
 * <TimeInput source="nb_views" />
 *
 * The object passed as `options` props is passed to the material-ui <TextField> component
 */
const TimeInput: FunctionComponent<TimeInputProps> = ({
  helperText,
  label,
  margin = "dense",
  onBlur,
  // onFocus,
  onChange,
  // options,
  step,
  resource,
  source,
  validate,
  variant = "filled",
  inputProps: overrideInputProps,
  ...rest
}) => {
  const time = ["0", "0"];

  const setMinuteInTime = (value: string) => {
    // console.log("Set minute", value);
    time[1] = value ? value : "0";
    return "" + (parseInt(time[0]) * 60 + parseInt(time[1]));
  };

  const setHourInTime = (value: string) => {
    // console.log("Set hour", value);
    time[0] = value ? value : "0";
    return "" + (parseInt(time[0]) * 60 + parseInt(time[1]));
  };

  const hourInput = useInput({
    format: getHourFromTime,
    onBlur,
    onChange,
    // onFocus,
    parse: setHourInTime,
    resource,
    source,
    type: "number",
    validate,
    ...rest,
  });

  const hourInputProps = { ...overrideInputProps, type: 'number', step: 1, min: 0, max: 23 };

  const minuteInput = useInput({
    format: getMinuteFromTime,
    onBlur,
    onChange,
    // onFocus,
    parse: setMinuteInTime,
    resource,
    source,
    type: "number",
    validate,
    ...rest,
  });

  const minuteInputProps = { ...overrideInputProps, type: 'number', step, min: 0, max: 59 };
  if (hourInput.field.value) time[0] = hourInput.field.value;
  if (minuteInput.field.value) time[1] = minuteInput.field.value;

  return (
    <Box display={{ xs: 'block', width: '100%' }}>
      <div>
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={hourInput.isRequired}
        />
      </div>
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <TextField
          id={hourInput.id}
          {...hourInput.field}
          variant={variant}
          error={
            (
              (hourInput.fieldState.isTouched || hourInput.formState.isSubmitted) &&
              (hourInput.fieldState.error)
            )
          }
          helperText={
            <InputHelperText
              touched={hourInput.fieldState.isTouched as boolean}
              error={hourInput.fieldState.error?.message}
              helperText={helperText}
            />
          }
          label={
            <FieldTitle
              label="Hour"
              source={source}
              resource={resource}
              isRequired={hourInput.isRequired}
            />
          }
          margin={margin}
          inputProps={hourInputProps}
          // {...options}
          {...sanitizeInputRestProps(rest)}
        />
        <TextField
          id={minuteInput.id}
          {...minuteInput.field}
          variant={variant}
          error={
            !!(
              minuteInput.fieldState.isTouched &&
              (minuteInput.fieldState.error)
            )
          }
          helperText={
            <InputHelperText
              touched={minuteInput.fieldState.isTouched as boolean}
              error={minuteInput.fieldState.error?.message}
              helperText={helperText}
            />
          }
          label={
            <FieldTitle
              label="Minute"
              source={source}
              resource={resource}
              isRequired={minuteInput.isRequired}
            />
          }
          margin={margin}
          inputProps={minuteInputProps}
          // {...options}
          {...sanitizeInputRestProps(rest)}
        />
      </Box>
    </Box>
  );
};

TimeInput.propTypes = {
  label: PropTypes.string,
  // options: PropTypes.object,
  resource: PropTypes.string,
  // source: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TimeInput.defaultProps = {
  // options: {},
  step: "any",
  // textAlign: "right",
};

export interface TimeInputProps
  extends InputProps<TextFieldProps>,
    Omit<
      TextFieldProps,
      | "label"
      | "helperText"
      | "onChange"
      | "onBlur"
      | "onFocus"
      | "defaultValue"
      | "type"
    > {
  step?: string | number;
}

export default TimeInput;
