import { Card, CardContent } from "@mui/material";
import { makeStyles } from '@mui/styles';
import GroupIcon from "@mui/icons-material/Group";
import CloudIcon from "@mui/icons-material/Cloud";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import CloudOff from "@mui/icons-material/CloudOff";
import PaymentIcon from "@mui/icons-material/Payment";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
// import OrderChart from "./chart/orders";
import { useState, useEffect, ReactElement } from "react";
import { useDataProvider } from "ra-core";
import { useNavigate } from "react-router-dom";

type StatsResponse = {
  id: string;
  user?: number;
  order?: {
    [key in
      | "NEW"
      | "WAITING_FOR_PAYMENT"
      | "PAID"
      | "COMPLETED"
      | "CANCELLED"
      | "REFUNDED"]?: {
      _count: {
        id: number;
      };
      _sum: {
        grossAmount: string;
      };
    };
  };
  transaction?: {
    [key in "INITIATED" | "SUCCESS" | "PENDING" | "FAILED"]?: {
      _count: {
        id: number;
      };
    };
  };
  resource: {
    [status in
      | "EXPIRED"
      | "EXHAUSTED"
      | "STOPPED"
      | "RUNNING"
      | "STARTING"
      | "REVOKED"]?: {
      _count: {
        id: number;
      };
      _sum: {
        maxUsage: number;
        currentUsage: number;
      };
    };
  };
};

const useStyles = makeStyles(() => ({
  statsContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 -5px",
  },
  // statsCard: {
  //   alignContent: "end",
  //   flexGrow: 1,
  //   color: theme.palette.secondary.light,
  //   backgroundColor: theme.palette.primary.main,
  //   margin: "3px 5px",
  //   cursor: "pointer"
  // },
  statsContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  statsHeader: {
    fontSize: "3em",
    fontWeight: 800,
    paddingBottom: "30px",
  },
  statsIcon: {
    fontSize: "6em",
    color: "#ff5983",
  },
}));

interface StatsProp {
  resource: string,
  icon: ReactElement,
  value: string | number,
  linkTo?: string
}

const Stats = ({ resource, icon, value, linkTo }: StatsProp) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Card sx={{
      alignContent: "end",
      flexGrow: 1,
      color: 'secondary.light',
      bgcolor: 'primary.main',
      margin: "3px 5px",
      cursor: "pointer"
    }} onClick={() => linkTo && navigate(linkTo)}>
      <CardContent className={classes.statsContent}>
        <div>
          <div className={classes.statsHeader}>{value}</div>
          <div>{resource}</div>
        </div>
        <div>{icon}</div>
      </CardContent>
    </Card>
  );
};

const ShowStats = () => {
  const classes = useStyles();
  const [statsLoaded, setStatsLoaded] = useState(false);
  const [stats, setStats] = useState<StatsResponse>();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getOne("stats", { id: "" }).then((resp) => {
      if(resp){
        setStats(resp.data as StatsResponse);
        setStatsLoaded(true);
      }
    });
  }, [dataProvider]);

  return (
    <div style={{ marginBottom: "10px" }}>
          {statsLoaded && stats && (
            <div className={classes.statsContainer}>
              {stats.user && (
                <Stats
                  resource="Registered Users"
                  value={stats.user}
                  icon={<GroupIcon className={classes.statsIcon} />}
                  linkTo="/users"
                />
              )}
              {stats.order && (
                <Stats
                  resource="Completed Orders"
                  value={
                    (stats.order.COMPLETED?._count.id || 0) +
                    (stats.order.PAID?._count.id || 0)
                  }
                  icon={<ShoppingBasketIcon className={classes.statsIcon} />}
                  linkTo="/orders"
                />
              )}
              {stats.order && (
                <Stats
                  resource="Pending Orders"
                  value={
                    (stats.order.NEW?._count.id || 0) +
                    (stats.order.WAITING_FOR_PAYMENT?._count.id || 0)
                  }
                  icon={<ShoppingBasketIcon className={classes.statsIcon} />}
                  linkTo="/orders"
                />
              )}
              {stats.order && (
                <Stats
                  resource="Total Sales"
                  value={
                    "₹" +
                    (parseFloat(
                      stats.order.COMPLETED?._sum.grossAmount || "0"
                    ) +
                      parseFloat(stats.order.PAID?._sum.grossAmount || "0"))
                  }
                  icon={<PaymentIcon className={classes.statsIcon} />}
                  linkTo="/orders"
                />
              )}
            </div>
          )}
          {statsLoaded && stats && (
            <div className={classes.statsContainer}>
              <Stats
                resource="Active Proxies"
                value={
                  (stats.resource.STARTING?._count.id || 0) +
                  (stats.resource.RUNNING?._count.id || 0) +
                  (stats.resource.STOPPED?._count.id || 0)
                }
                icon={<CloudIcon className={classes.statsIcon} />}
                linkTo={`/resources?filter=${JSON.stringify({status: ["STARTING","RUNNING","STOPPED"]})}`}
              />
              <Stats
                resource="Running Proxies"
                value={stats.resource.RUNNING?._count.id || 0}
                icon={<CloudDoneIcon className={classes.statsIcon} />}
                linkTo={`/resources?filter=${JSON.stringify({status: ["RUNNING"]})}`}
              />
              <Stats
                resource="Expired Proxies"
                value={
                  (stats.resource.EXHAUSTED?._count.id || 0) +
                  (stats.resource.EXPIRED?._count.id || 0)
                }
                icon={<CloudOff className={classes.statsIcon} />}
                linkTo={`/resources?filter=${JSON.stringify({status: ["EXPIRED","EXHAUSTED"]})}`}
              />
              <Stats
                resource="Remaining Hours"
                value={
                  Math.ceil(((stats.resource.STARTING?._sum.maxUsage || 0) +
                  (stats.resource.RUNNING?._sum.maxUsage || 0) +
                  (stats.resource.STOPPED?._sum.maxUsage || 0) -
                  ((stats.resource.STARTING?._sum.currentUsage || 0) +
                    (stats.resource.RUNNING?._sum.currentUsage || 0) +
                    (stats.resource.STOPPED?._sum.currentUsage || 0)))/60)
                }
                icon={<DataUsageIcon className={classes.statsIcon} />}
                linkTo="/resources"
              />
            </div>
          )}
      </div>
  );
}

export default ShowStats;