import React from "react";
import {
  ChipField,
  Datagrid,
  List,
  NumberField,
  TextField,
  BooleanInput,
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  Filter,
  FilterProps,
  ReferenceField,
  ReferenceInput,
  CloneButton,
  EditButton,
} from "react-admin";
import { SwitchButton } from "../lib/ra-rf/SwitchButton";
import { DateTimeField } from "../lib/ra-rf/fields";
import { CLOUD_PROVIDER, Currency } from "../models";

export type Availability = {
  id: string;
  resourceType: "PROXY";
  name: string;
  description: string;
  currency: Currency;
  price: string;
  validity?: number;
  maxUsage?: number;
  provider: string;
};

const RESOURCE_TYPE = [
  { id: "PROXY", name: "PROXY" },
  { id: "VPN", name: "VPN" },
];

const AvailabilityFilter = (
  props: Omit<FilterProps, "children">
): React.ReactElement => (
  <Filter {...props}>
    <BooleanInput source="isActive" />
    <SelectInput source="provider" choices={CLOUD_PROVIDER} alwaysOn />
  </Filter>
);

export const AvailabilityList = (): React.ReactElement => (
  <List
    sort={{ field: "createdAt", order: "DESC" }}
    filters={<AvailabilityFilter />}
  >
    <Datagrid>
      <TextField source="id" />
      <ReferenceField source="regionId" reference="regions">
        <TextField source="name" />
      </ReferenceField>
      <ChipField color="secondary" source="resourceType" />
      <ChipField color="secondary" source="provider" />
      <TextField source="instanceType" />
      <TextField source="location" />
      <NumberField source="available" />
      <NumberField source="inuse" />
      <SwitchButton source="isActive" />
      <NumberField source="priority" />
      <DateTimeField source="createdAt" />
      <EditButton />
      <CloneButton />
    </Datagrid>
  </List>
);

export const AvailabilityEdit = (): React.ReactElement => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" />
      <ReferenceInput source="regionId" reference="regions">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput source="resourceType" choices={RESOURCE_TYPE} />
      <TextInput source="provider" />
      <TextInput source="instanceType" />
      <TextInput source="location" />
      <NumberInput source="available" />
      <NumberInput source="inuse" />
      <BooleanInput source="isActive" />
      <NumberInput source="priority" />
    </SimpleForm>
  </Edit>
);

export const AvailabilityCreate = (): React.ReactElement => (
  <Create>
    <SimpleForm>
      <TextInput source="id" />
      <ReferenceInput source="regionId" reference="regions">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SelectInput source="resourceType" choices={RESOURCE_TYPE} />
      <TextInput source="provider" />
      <TextInput source="instanceType" />
      <TextInput source="location" />
      <NumberInput source="available" />
      <NumberInput source="inuse" />
      <BooleanInput source="isActive" />
      <NumberInput source="priority" />
    </SimpleForm>
  </Create>
);
