import { useDataProvider, RaRecord /* useNotify */ } from "ra-core";
import { ReactElement, useEffect, useState } from "react";
// import { makeStyles } from "@mui/styles";
import { Box, Card, CardContent, Button, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import { BillingAddressCreate } from "./addresses";
import {
  Identifier,
  useAuthProvider,
  useNotify,
  UserIdentity,
} from "react-admin";
import { loadScript } from "../lib/utils";
import restFramework from "../provider/rf";

// const useStyles = makeStyles((theme) => ({
//   modalDiv: {
//     position: "absolute",
//     width: 400,
//     backgroundColor: theme.palette.background.paper,
//     border: `2px solid ${theme.palette.primary.dark}`,
//     boxShadow: theme.shadows[5],
//     /* padding: theme.spacing(2, 4, 3), */
//     left: "50%",
//     top: "50%",
//     transform: "translate(-50%,-50%)",
//   },
// }));

export interface NewOrderProps {
  planId: string;
  orderSuccess: (json: any) => void;
  orderError: (message: string) => void;
  resourceIds?: Identifier[];
}

export const NewOrder = ({
  planId,
  orderSuccess,
  orderError,
  resourceIds,
}: NewOrderProps): ReactElement => {
  // const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();
  const [chooseQuantity, setChooseQuantity] = useState(false);
  const [quantity, setQuantity] = useState(
    resourceIds ? resourceIds.length : 1
  );
  // console.log("quantity", quantity);
  const [coupon, setCoupon] = useState("");
  const [enterAddress, setEnterAddress] = useState(false);
  const [user, setUser] = useState<UserIdentity>();
  // const [order, setOrder] = useState<Record>();
  // const [transaction, setTransaction] = useState<Record>();

  useEffect(() => {
    authProvider.getIdentity &&
      authProvider.getIdentity().then((user) => setUser(user));
  }, [authProvider]);

  useEffect(() => {
    if (resourceIds) {
      setQuantity(resourceIds.length);
    }
  }, [resourceIds]);

  useEffect(() => {
    if (planId) {
      // setOrder(undefined);
      // setTransaction(undefined);
      if (user && user.billingAddressId) {
        setChooseQuantity(true);
      } else {
        setEnterAddress(true);
      }
    }
  }, [planId]);

  const addressCreated = async () => {
    user && setUser({ ...user, billingAddressId: "New" });
    setEnterAddress(false);
    return setChooseQuantity(true);
  };

  const startPayment = async (transaction: RaRecord, order: RaRecord) => {
    try {
      if (!transaction) throw Error("Transaction does not exist");
      console.log(order, transaction);
      if (typeof (window as any).Razorpay === "undefined") {
        const razorpayLoaded = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!razorpayLoaded) {
          throw Error("Unable to load razorpay");
        }
      }
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: (parseFloat(transaction.amount) * 100).toString(),
        currency: transaction.currency ?? "INR",
        name: "Cloudproxy",
        description: "Cloudproxy plan",
        image: "https://www.cloudproxy.in/assets/icon/apple-icon-180x180.png",
        order_id: transaction.pgOrderId,
        handler: async (response: any) => {
          console.log(response);
          const data = {
            orderId: transaction.pgOrderId,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          console.log(data);
          restFramework
            .post("payments/razorpay/success", data)
            .then(orderSuccess)
            .catch((err) => {
              console.log(err);
              orderError("Payment failed");
            });
        },
        modal: {
          ondismiss: function () {
            orderError("Payment cancelled");
          },
        },
        prefill: {
          name: user?.fullName,
          email: user?.email,
          contact: user?.mobile,
        },
        theme: {
          color: "#f50057",
        },
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paymentObject = new (window as any).Razorpay(options);
      paymentObject.on("payment.failed", (response: any) => {
        console.error(response);
        orderError("Payment Failed");
      });
      return paymentObject.open();
    } catch (err: any) {
      orderError(`Payment error ${err.message}`);
    }
  };

  const createTransaction = async (order: RaRecord) => {
    try {
      if (order) {
        const transactionResult = await dataProvider.create("transactions", {
          data: { orderId: order.id, pg: "RAZORPAY" },
        });
        // setTransaction(transactionResult.data);
        return startPayment(transactionResult.data, order);
      } else {
        throw Error("Order not set");
      }
    } catch (err: any) {
      orderError(`Transaction error: ${err.message}`);
    }
  };

  const createOrder = async (selectedPlanId?: string) => {
    try {
      const orderResult = await dataProvider.create("orders", {
        data: {
          planId: selectedPlanId || planId,
          quantity,
          couponCode: coupon.length ? coupon : undefined,
          renewals: resourceIds ? resourceIds : undefined,
        },
      });
      console.log(orderResult);
      // setOrder(orderResult.data);
      setChooseQuantity(false);
      return createTransaction(orderResult.data);
    } catch (err: any) {
      notify(err.message);
    }
  };

  const handleClose = () => {
    setEnterAddress(false);
    setChooseQuantity(false);
    orderError("Order Cancelled");
  };

  return (
    <Card elevation={0} style={{ maxWidth: "900px" }}>
      {/* <CardHeader title="Purchase New Proxy" /> */}
      <div>
        <Modal
          open={enterAddress}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: 400,
              bgcolor: 'background.paper',
              border: 2,
              borderColor: 'primary.dark',
              boxShadow: 5,
              /* padding: theme.spacing(2, 4, 3), */
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <BillingAddressCreate
              // onSuccess={addressCreated}
              // basePath="/"
              mutationOptions={{onSuccess: addressCreated}}
              resource="addresses"
            />
          </Box>
        </Modal>
        <Modal open={chooseQuantity} onClose={handleClose}>
          <Box sx={{
              position: "absolute",
              width: 400,
              bgcolor: 'background.paper',
              border: 2,
              borderColor: 'primary.dark',
              boxShadow: 5,
              /* padding: theme.spacing(2, 4, 3), */
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}>
            <Card>
              <CardContent>
                <TextField
                  style={{ width: "100%" }}
                  type="number"
                  inputProps={{ min: 1, max: 20 }}
                  label="Quantity"
                  helperText={
                    resourceIds
                      ? "Renewing selected resource"
                      : "Can purchase upto 20 proxies at once"
                  }
                  placeholder="Enter Quantity"
                  value={quantity}
                  disabled={!!resourceIds}
                  onChange={(evt) => setQuantity(parseInt(evt.target.value))}
                />
                <TextField
                  style={{ width: "100%" }}
                  label="Coupon"
                  placeholder="Enter coupon code"
                  onChange={(evt) => setCoupon(evt.target.value)}
                  value={coupon}
                />
                <Button
                  onClick={() => createOrder()}
                  variant="contained"
                  color="primary"
                >
                  Make Payment
                </Button>
              </CardContent>
            </Card>
          </Box>
        </Modal>
      </div>
    </Card>
  );
};
