import {
  BooleanField,
  Datagrid,
  List,
  TextField,
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  DateTimeInput,
} from "react-admin";
import { DateTimeField } from "../lib/ra-rf/fields";

export const SubdomainList = () => (
  <List sort={{ field: "order", order: "ASC" }} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="order" />
      <BooleanField source="reserved" />
      <TextField source="dnsRecordId" />
      <DateTimeField source="expiredAt" />
      <DateTimeField source="updatedAt" />
    </Datagrid>
  </List>
);

export const SubdomainEdit = () => (
  <Edit>
    <SimpleForm>
      <TextField source="id" />
      <BooleanInput source="reserved" />
      <TextInput source="dnsRecordId" />
      <DateTimeInput source="expiredAt" />
    </SimpleForm>
  </Edit>
);

export const SubdomainCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="id" />
      <BooleanInput source="reserved" />
      <TextInput source="dnsRecordId" />
    </SimpleForm>
  </Create>
);
