import React from 'react';
import {Popover, PopoverProps} from '@mui/material'

const DropDown = (props: Omit<PopoverProps, 'open'>) => {
  const {children, anchorEl} = props;
  const popupOpen = Boolean(anchorEl);
  const popupId = popupOpen ? "simple-popver" : undefined;
  return (
    <Popover
        id={popupId}
        open={popupOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        {...props}
      >
        {children}
      </Popover>
  );
}

export default DropDown;