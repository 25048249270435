import { DefaultCurrency, SupportedCurrencies } from "../models";
import Countries from "./country.json";


export type COUNTRY = {
  name: string;
  countryCode: string;
  countryCodeAlpha3: string;
  phone: string;
  currency: string;
  stateProvinces: {
    name: string;
  }[] | null;
}

export const countriesByName = Countries.reduce((result, country) => {
  result[country.name] = country;
  return result;
}, {} as Record<string, COUNTRY>);

export const countriesByCode = Countries.reduce((result, country) => {
  result[country.countryCode] = country;
  return result;
}, {} as Record<string, COUNTRY>);

export const countryList = Countries.map((country) => ({
  id: country.name,
  name: country.name,
}));

export const getCurrency = (countryCode: string) => {
  if(countriesByCode[countryCode] && Object.keys(SupportedCurrencies).indexOf(countriesByCode[countryCode].currency)!==-1){
    return countriesByCode[countryCode].currency;
  }
  return DefaultCurrency;
}