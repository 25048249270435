export const CLOUD_PROVIDER = [
  {id: "AWS", name: "AWS"},
  {id: "AZURE", name: "Azure"},
  {id: "DIGITALOCEAN", name: "Digitalocean"},
]

export const RESOURCE_STATUS = [
  { id: "STOPPED", name: "Stopped" },
  { id: "RUNNING", name: "Running" },
  { id: "EXPIRED", name: "Expired" },
  { id: "STARTING", name: "Starting" },
  { id: "EXHAUSTED", name: "Exhausted" },
  { id: "REVOKED", name: "Banned" },
];

export const ORDER_STATUS = [
  { id: "NEW", name: "NEW" },
  { id: "WAITING_FOR_PAYMENT", name: "WAITING_FOR_PAYMENT" },
  { id: "PAID", name: "PAID" },
  { id: "COMPLETED", name: "COMPLETED" },
  { id: "CANCELLED", name: "CANCELLED" },
  { id: "REFUNDED", name: "REFUNDED" }
];

export const TRANSACTION_STATUS = [
  { id: "INITIATED", name: "INITIATED" },
  { id: "SUCCESS", name: "SUCCESS" },
  { id: "PENDING", name: "PENDING" },
  { id: "FAILED", name: "FAILED" }
];

export const RESOURCE_RUNNING_STATUS = [
  { id: "STARTING", name: "STARTING" },
  { id: "STOPPED", name: "STOPPED" },
  { id: "RUNNING", name: "RUNNING" }
];

export const SupportedCurrencies = {
  "INR": "₹ Rupees",
  "USD": "$ Dollar"
}

export const CURRENCIES = [
  {id: "INR", name: "₹ Rupees"},
  {id: "USD", name: "$ Dollar"},
]

export type Currency = keyof typeof SupportedCurrencies;

export const DefaultCurrency: Currency = "USD";

export const CURRENCY_SYMBOL: Record<Currency,string> = {
  USD: "$",
  INR: "₹",
}

export const COUPON_TYPES = [
  {id: "FLAT", name: "Flat"},
  {id: "PERC", name: "Percentage"},
]